import * as React from "react";
import Card from "@mui/material/Card";
import Button from "@mui/material/Button";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Masonry from "@mui/lab/Masonry";
import CardMedia from "@mui/material/CardMedia";
import { useMediaQuery } from "@mui/material";
import { productscategories } from "../data/products"; // Import the JSON file
import { Link, useLocation, useNavigate } from "react-router-dom";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";

const createProductCards = (product) => (
  <Card sx={{ width: { xs: "auto", sm: "350px" }, margin: 1 }}>
    <CardMedia
      component="img"
      height="140"
      image={product.image}
      alt="Item 1"
    />
    <CardContent>
      <Typography variant="h6">{product.name}</Typography>

      {/* Centered Submit Button */}
      <Box display="flex" justifyContent="center" marginTop="12px">
        <Button
          variant="outlined"
          color="primary"
          type="submit"
          component={Link}
          to={`/products/${product.name
            .replace(/ /g, "_")
            .toString()
            .toLowerCase()}`}
        >
          View Products
        </Button>
      </Box>
    </CardContent>
  </Card>
);
const createProductCards1 = (product) => (
  <>
    <div class="mx-auto my-2 max-w-screen-xl  ">
      <div class="group cursor mx-1 overflow-hidden rounded-2xl bg-white shadow-xl duration-200 hover:-translate-y-4">
        <div class="flex h-36 flex-col justify-between overflow-hidden">
          <img
            src={product.image}
            class="group-hover:scale-110 h-full w-full object-cover duration-200"
          />
        </div>
        <div class="flex-1 overflow-hidden bg-white px-2 py-3">
          <Link
            to={`/products/${product.name.replace(/ /g, "_").toLowerCase()}`}
            className="block" // Ensure the Link is styled as a block element if needed
          >
            <p class=" mb-4 text-black text-base ">{product.name}</p>

            <a
              href="#"
              class="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-red-700 rounded-lg hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 "
            >
              Products
              <svg
                class="rtl:rotate-180 w-3.5 h-3.5 ms-2"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 14 10"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M1 5h12m0 0L9 1m4 4L9 9"
                />
              </svg>
            </a>
          </Link>
        </div>
      </div>
    </div>

    {/* <div class="max-w-sm rounded overflow-hidden shadow-lg">
      <img class="w-full" src={product.image} alt="Sunset in the mountains" />
      <div class="px-6 py-4">
        <div class="font-bold text-base mb-2">{product.name}</div>
      </div>
      <a
        href="#"
        class="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
      >
        View Products
        <svg
          class="rtl:rotate-180 w-3.5 h-3.5 ms-2"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 14 10"
        >
          <path
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M1 5h12m0 0L9 1m4 4L9 9"
          />
        </svg>
      </a>
    </div> */}

    {/* <div class="max-w-sm bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
      <a href="#">
        <img class="rounded-t-lg" src={product.image} alt="" />
      </a>
      <div class="p-5">
        <a href="#">
          <h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
            Noteworthy technology acquisitions 2021
          </h5>
        </a>
        <p class="mb-3 font-normal text-gray-700 dark:text-gray-400">
          Here are the biggest enterprise technology acquisitions of 2021 so
          far, in reverse chronological order.
        </p>
        <a
          href="#"
          class="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
        >
          Read more
          <svg
            class="rtl:rotate-180 w-3.5 h-3.5 ms-2"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 14 10"
          >
            <path
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M1 5h12m0 0L9 1m4 4L9 9"
            />
          </svg>
        </a>
      </div>
    </div> */}
  </>
);
// Function to shuffle the array
const shuffleArray = (array) => {
  let shuffledArray = [...array];
  for (let i = shuffledArray.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
  }
  return shuffledArray;
};

// Function to get 6 random products
const getRandomProducts = (array, numProducts) => {
  if (numProducts === 0) return array;
  const shuffledArray = shuffleArray(array);
  return shuffledArray.slice(0, numProducts);
};

export default function ProductCategories() {
  const navigate = useNavigate(); // Hook for navigation
  const isSmallScreen = useMediaQuery("(max-width:600px)");
  const location = useLocation();
  const currentPath = location.pathname;
  const columns = isSmallScreen ? 1 : 3;
  const getAll = currentPath.includes("product") ? 0 : 6;
  const randomProducts = getRandomProducts(productscategories, getAll);

  return (
    <Box
      id="productCategories"
      sx={{
        pt: { xs: 4, sm: 4 },
        color: "white",
      }}
      backgroundColor="#fef2f2"
    >
      <Container
        sx={{
          pt: { xs: 4, sm: 4 },
          pb: { xs: 4, sm: 6 },
          position: "relative",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: { xs: 2, sm: 4 },
        }}
      >
        <Box
          sx={{
            width: { sm: "100%", md: "60%" },
            textAlign: { sm: "left", md: "center" },
          }}
        >
          <Typography component="h2" variant="h4" color="text.primary">
            Product Categories
          </Typography>
        </Box>
        <Masonry columns={columns} spacing={2}>
          {randomProducts.map((product, index) => (
            <div key={index}>{createProductCards1(product)}</div>
          ))}
        </Masonry>
      </Container>
    </Box>
  );
}
