import React, { useState, useEffect } from "react";
import {
  TextField,
  Button,
  Box,
  Typography,
  Grid,
  Container,
  Card,
  CardMedia,
  CardContent,
  useMediaQuery,
} from "@mui/material";
import emailjs from "emailjs-com";
import { toast } from "react-toastify";
import { products } from "../data/products"; // Import the JSON file
import { useParams, useNavigate } from "react-router-dom";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";

const ProductCheckout = () => {
  const isSmallScreen = useMediaQuery("(max-width:600px)");

  const { id } = useParams(); // Get the product ID from the URL
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    contact: "",
    message: "",
  });
  const [product, setProduct] = useState(null);
  const navigate = useNavigate(); // Hook for navigation

  useEffect(() => {
    // Find the product based on the ID from the URL
    const foundProduct = products.find(
      (product) => product.id === parseInt(id)
    );
    setProduct(foundProduct);
    setFormData({
      name: "",
      email: "",
      message: "Enquiry about " + foundProduct.category,
      contact: "+971 ",
    });
  }, [id]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (!formData.name.trim() || !formData.contact.trim()) {
      toast.error("Name & Phone are required", { theme: "dark" });
      return;
    }
    toast.info("Sending Message...", {
      theme: "dark",
    });
    // Send email using EmailJS
    emailjs
      .send(
        "service_gtqk1ha",
        "template_ofztrsn",
        formData,
        "aPy4gCYatwh0FaQxp"
      )
      .then((response) => {
        console.log("Email successfully sent:", response);
        toast.success("Message received, our team will get in touch!", {
          theme: "dark",
        });
        setFormData({
          name: "",
          email: "",
          message: "",
          contact: "",
        });
      })
      .catch((error) => {
        toast.error("Error while sending message", { theme: "dark" });
        console.error("Error sending email:", error);
      });
  };

  return (
    <div>
      <Box
        id="getintouch"
        sx={{
          pt: { xs: 4, sm: 4 },
          pb: { xs: 4, sm: 6 },
          color: "white",
        }}
      >
        <Container
          sx={{
            position: "relative",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Button
            onClick={() => navigate(-1)} // Navigate back to the previous page
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              zIndex: 1,
            }}
          >
            <ArrowLeftIcon
              sx={{
                color: "black",
              }}
            />
            {!isSmallScreen && <p>Previous Page</p>}
          </Button>
          <Box
            sx={{
              width: { sm: "100%", md: "80%" },
              textAlign: { sm: "left", md: "center" },
            }}
          >
            <Typography component="h2" variant="h4" color="text.primary">
              Product Enquiry
            </Typography>
          </Box>
          <Grid container spacing={2}>
            {/* Product Details Column */}
            <Grid item xs={12} md={6}>
              <Box display="flex" flexDirection="column" height="100%">
                {product ? (
                  <Card
                    sx={{
                      margin: 1,
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <CardMedia
                      component="img"
                      image={product.image || "default-image.jpg"} // Provide a default image if none is available
                      alt={product.name}
                      sx={{
                        width: 180, // Use number instead of string for consistency
                        height: 180,
                        objectFit: "contain", // Adjust this if needed
                        display: "block", // Ensure the image is treated as a block element
                        margin: "auto", // Center the image horizontally
                      }}
                    />
                    <CardContent sx={{ textAlign: "center" }}>
                      <Typography variant="h6">{product.name}</Typography>
                      <Typography variant="body2">
                        {product.category || "No description available"}
                      </Typography>
                    </CardContent>
                  </Card>
                ) : (
                  <Typography variant="body1">Product not found</Typography>
                )}
              </Box>
            </Grid>

            {/* Contact Form Column */}
            <Grid item xs={12} md={6}>
              <Box display="flex" flexDirection="column" height="100%">
                <TextField
                  label="Name"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  name="name"
                  value={formData.name}
                  InputLabelProps={{
                    style: { top: "-6px" }, // Adjust these values as needed
                  }}
                  onChange={handleChange}
                  sx={{
                    marginBottom: 1,
                  }}
                />
                <TextField
                  label="Phone(with ext.)"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  type="tel"
                  name="contact"
                  value={formData.contact}
                  InputLabelProps={{
                    style: { top: "-6px" }, // Adjust these values as needed
                  }}
                  onChange={handleChange}
                  sx={{
                    marginBottom: 1,
                  }}
                />
                <TextField
                  label="Message"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  name="message"
                  marginBottom="6px"
                  value={formData.message}
                  InputLabelProps={{
                    style: { top: "-6px" }, // Adjust these values as needed
                  }}
                  onChange={handleChange}
                />
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  onClick={handleSubmit}
                >
                  Submit
                </Button>
                <p style={{ color: "black" }}>OR</p>
                <Button
                  variant="contained"
                  type="submit"
                  onClick={() => {
                    window.open(
                      "https://wa.me/message/E6ISHYZGBBKAE1",
                      "_blank"
                    );
                  }}
                  sx={{ background: "#075e54" }}
                >
                  <WhatsAppIcon
                    sx={{
                      color: "white",
                      marginRight: "2px",
                    }}
                  />
                  WhatsApp Us!
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </div>
  );
};

export default ProductCheckout;
