// src/analytics.js
import ReactGA from "react-ga4";

export const initGA = () => {
  ReactGA.initialize("G-Q049RCQYF4"); // Replace with your Measurement ID
};

export const logPageView = () => {
  ReactGA.send("pageview");
};
