import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css"; // Import default styles
import { Box, Typography, Card } from "@mui/material";
import "../styles/Carousel.css"; // Import your custom styles
import brands from "../data/brand"; // Import the JSON file

// Define responsive breakpoints
const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 8,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 4,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 2,
  },
};

// Function to shuffle the brands array
const shuffleArray = (array) => {
  let shuffledArray = [...array];
  for (let i = shuffledArray.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
  }
  return shuffledArray;
};

// Function to create product cards
const createProductCards = (brand) => (
  <Card
    sx={{
      height: "80px",
      margin: 0.5,
      backgroundColor: "white",
      display: "flex", // Ensure the card is a flex container
      alignItems: "center", // Center the image vertically
      justifyContent: "center", // Center the image horizontally
      overflow: "hidden", // Hide any overflow from the image
    }}
  >
    <img
      src={brand.image}
      alt={brand.name}
      style={{
        width: "100%",
        height: "100%",
        objectFit: "contain", // Adjust this if needed (cover, fill, etc.)
      }}
    />
  </Card>
);

// Component definition
const BrandCarousel = () => {
  // Shuffle brands and store in a variable
  const shuffledBrands = shuffleArray(brands);
  const shuffledBrands1 = shuffleArray(brands);

  return (
    <Box
      className="carousel-container"
      sx={{
        pt: { xs: 4, sm: 4 },
        pb: { xs: 4, sm: 6 },
        pl: 2,
      }}
      backgroundColor="white"
    >
      <Typography
        variant="h4"
        gutterBottom
        align="center"
        sx={{ marginBottom: 2 }}
      >
        Advantage Brands
      </Typography>
      {/* First Carousel */}
      <Box>
        {" "}
        {/* Add margin-bottom for spacing between carousels */}
        <Carousel
          responsive={responsive}
          infinite={true}
          autoPlay={true}
          autoPlaySpeed={1700}
          arrows={false} // Hide side navigation buttons
        >
          {shuffledBrands
            .filter((brand) => !brand.image.includes("smartgtlogo")) // Filter out brands containing 'msartgt'
            .map((brand, index) => (
              <div key={index}>{createProductCards(brand)}</div>
            ))}
        </Carousel>
      </Box>

      {/* Second Carousel */}
      <Box>
        <Carousel
          responsive={responsive}
          infinite={true}
          autoPlay={true}
          autoPlaySpeed={1700}
          arrows={false} // Hide side navigation buttons
        >
          {shuffledBrands1
            .filter((brand) => !brand.image.includes("smartgtlogo")) // Filter out brands containing 'msartgt'
            .map((brand, index) => (
              <div key={index}>{createProductCards(brand)}</div>
            ))}
        </Carousel>
      </Box>
    </Box>
  );
};

export default BrandCarousel;
