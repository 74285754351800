import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  useLocation,
} from "react-router-dom";
import "./App.css";
import LandingPage from "./pages/LandingPage";
import BrandsPage from "./pages/BrandsPage";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import getLPTheme from "./styles/getLPTheme";
import AppBarSticky from "./components/AppBarSticky";
import ProductsPage from "./pages/ProductsPage";
import ProductCategories from "./components/ProductCategories";
import ProductCheckout from "./components/ProductCheckout";
import Footer from "./components/Footer";
import { Divider } from "@mui/material";
import GetInTouch from "./components/GetInTouch";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import { initGA, logPageView } from "./analytics";

// Initialize Google Analytics
initGA();
// Component to handle page view logging
const RouteChangeTracker = () => {
  const location = useLocation();

  React.useEffect(() => {
    logPageView();
  }, [location]);

  return null;
};

function App() {
  const LPtheme = createTheme(getLPTheme("light"));

  return (
    <div className="App">
      <ThemeProvider theme={LPtheme}>
        <Router>
          <RouteChangeTracker />

          <CssBaseline />
          <AppBarSticky />
          <Routes>
            <Route path="/" element={<LandingPage />} />
            <Route path="/brands" element={<BrandsPage />} />
            <Route path="/products/:category" element={<ProductsPage />} />
            <Route path="/products" element={<ProductCategories />} />
            <Route path="/checkout/:id" element={<ProductCheckout />} />
            <Route
              path="/e-brouchure/"
              element={<GetInTouch brouchure={true} />}
            />
            {/* Redirect any undefined route to the homepage */}
            <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>
          <Divider />
          <Footer />
          <div
            onClick={() => {
              window.open("https://wa.me/message/E6ISHYZGBBKAE1", "_blank");
            }}
            rel="noopener noreferrer"
            className="whatsapp-icon"
            aria-label="WhatsApp"
          >
            <WhatsAppIcon />
          </div>
        </Router>
        <ToastContainer />
      </ThemeProvider>
    </div>
  );
}

export default App;
