import * as React from "react";

import bg from "../assets/cover-photo1.jpg";
import {
  Stack,
  Box,
  Typography,
  Container,
  useMediaQuery,
  useTheme,
} from "@mui/material";
export default function Hero() {
  const theme = useTheme();

  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm")); // Check if screen width is small

  return (
    <Box
      id="hero"
      sx={(theme) => ({
        position: "relative",
        width: "100%",
        height: "350px", // Adjust based on your needs
        backgroundImage: `url(${bg})`, // Replace with your image path
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        overflow: "hidden",
        "&::before": {
          content: '""',
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundColor: "rgba(0, 0, 0, 0.8)", // Black with 50% opacity
          zIndex: 1,
        },
      })}
    >
      <Container
        sx={{
          position: "relative",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          pt: { xs: 10, sm: 15 },
          pb: { xs: 8, sm: 8 },
          zIndex: 2,
          color: "white", // Ensures text is visible on dark background
        }}
      >
        <Stack spacing={2} useFlexGap sx={{ width: { xs: "100%", sm: "95%" } }}>
          {isSmallScreen ? (
            <>
              <Typography
                component="h2"
                variant="h2"
                sx={{
                  display: "flex",
                  flexDirection: { xs: "column", md: "row" },
                  alignSelf: "center",
                  textAlign: "center",
                }}
              >
                Your gateway to&nbsp;
                <Typography
                  component="span"
                  variant="h2"
                  sx={{
                    color: "#B71C1C",
                  }}
                >
                  Global Markets
                </Typography>
              </Typography>
            </>
          ) : (
            <>
              {" "}
              <Typography
                component="h1"
                variant="h1"
                sx={{
                  display: "flex",
                  flexDirection: { xs: "column", md: "row" },
                  alignSelf: "center",
                  textAlign: "center",
                }}
              >
                Your gateway to&nbsp;
                <Typography
                  component="span"
                  variant="h1"
                  sx={{
                    color: "#B71C1C",
                  }}
                >
                  Global Markets
                </Typography>
              </Typography>
              <Typography variant="body1" textAlign="center" color="white">
                <b>Smart global general trading</b> is a 100% UAE national
                company established with the aim of providing excellent and
                satisfactory supply and trading services to our customers.
                <br />
                Elevate your experience with our top-tier products and services.
              </Typography>
            </>
          )}
        </Stack>
      </Container>
    </Box>
  );
}
