import React from "react";
import {
  Autocomplete,
  TextField,
  Box,
  InputAdornment,
  IconButton,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { products } from "../data/products";
import { useNavigate } from "react-router-dom";
const SearchBar = () => {
  const navigate = useNavigate();

  const handleSelection = (event, value) => {
    if (value) {
      const selectedProduct = products.find(
        (product) => product.name === value
      );
      if (selectedProduct) {
        navigate(`/checkout/${selectedProduct.id}`);
      }
    }
  };

  return (
    <Box sx={{ width: "100%", maxWidth: 600, mx: "auto" }}>
      <Autocomplete
        freeSolo
        options={products.map((item) => item.name)}
        filterOptions={(options, state) => {
          if (state.inputValue.length < 2) {
            return [];
          }
          return options.filter((option) =>
            option.toLowerCase().includes(state.inputValue.toLowerCase())
          );
        }}
        onChange={handleSelection}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="filled"
            label="Search products/brands..."
            InputLabelProps={{
              style: { top: "-6px", color: "black" }, // Adjust as needed
            }}
            InputProps={{
              ...params.InputProps,
              style: { color: "black" },
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton edge="end">
                    <SearchIcon
                      style={{ color: "black", marginBottom: "16px" }}
                    />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            sx={{
              "& .MuiInputBase-input": {
                color: "black", // Set text color inside input
              },
              "& .MuiInputLabel-root": {
                color: "black", // Set label color
              },
              "& .MuiFilledInput-root": {
                backgroundColor: "rgba(255, 255, 255, 1)", // Adjust background color
                "&:hover": {
                  backgroundColor: "rgba(240, 240, 240, 1)", // Background color on hover
                },
                "&.Mui-focused": {
                  backgroundColor: "rgba(240, 240, 240, 1)", // Focused background color
                },
                borderRadius: "4px",
                "&:hover fieldset": {
                  borderColor: "black", // Border color on hover
                },
              },
              "& .MuiInputBase-input::placeholder": {
                color: "black", // Set placeholder color
              },
            }}
          />
        )}
      />
    </Box>
  );
};

export default SearchBar;
