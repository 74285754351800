import React, { useState } from "react";
import {
  TextField,
  Button,
  Box,
  Typography,
  Grid,
  Container,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import PhoneOutlinedIcon from "@mui/icons-material/PhoneOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";

import emailjs from "emailjs-com";
import { toast } from "react-toastify";

const GetInTouch = ({ brouchure }) => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    contact: "+971",
    message: "",
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const theme = useTheme();

  // Media queries to determine screen size
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm")); // Check if screen width is small

  // Handle form submission
  const handleSubmit = (event) => {
    event.preventDefault();
    if (
      !formData.name.trim() ||
      !formData.contact.trim() ||
      !formData.email.trim()
    ) {
      toast.error("Name/Phone/Email is required", { theme: "dark" });
      return;
    }
    toast.info("Sending Message...", {
      theme: "dark",
    });
    // Send email using EmailJS
    emailjs
      .send(
        "service_gtqk1ha",
        "template_uysulmt",
        formData,
        "aPy4gCYatwh0FaQxp"
      )
      .then((response) => {
        console.log("Email successfully sent:", response);
        toast.success("Message received, our team will get in touch!", {
          theme: "dark",
        });
        setFormData({
          name: "",
          email: "",
          message: "",
          contact: "",
        });
      })
      .catch((error) => {
        toast.error("Errow while sending message", { theme: "dark" });
        console.error("Error sending email:", error);
      });
  };

  return (
    <div>
      <Box
        id="getintouch"
        sx={{
          pt: { xs: 4, sm: 4 },
          pb: { xs: 4, sm: 6 },
          color: "white",
        }}
      >
        <Container
          sx={{
            position: "relative",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              width: { sm: "100%", md: "80%" },
              textAlign: { sm: "left", md: "center" },
            }}
          >
            {brouchure ? (
              <Typography component="h2" variant="h4" color="text.primary">
                Details required for E-Brouchure
              </Typography>
            ) : (
              <Typography component="h2" variant="h4" color="text.primary">
                Get In Touch
              </Typography>
            )}

            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              sx={{ padding: 2 }}
            >
              <Box
                display="flex"
                flexDirection={isSmallScreen ? "column" : "row"}
                alignItems="center"
                justifyContent="space-between"
                width="100%"
                sx={{ gap: 4, textAlign: "center" }} // Add spacing and center text
              >
                <Box display="flex" flexDirection="column" alignItems="center">
                  <LocationOnOutlinedIcon
                    sx={{
                      fontSize: isSmallScreen ? 40 : 60, // Adjust icon size based on screen size
                      color: "black",
                    }}
                  />
                  <Typography variant="body2" sx={{ mt: 1 }} color="#b91c1c">
                    Abu Dhabi, UAE
                  </Typography>
                </Box>
                <Box display="flex" flexDirection="column" alignItems="center">
                  <PhoneOutlinedIcon
                    sx={{
                      fontSize: isSmallScreen ? 40 : 60, // Adjust icon size based on screen size
                      color: "black",
                    }}
                  />
                  <Typography variant="body2" sx={{ mt: 1 }} color="#b91c1c">
                    +971 552 553 056
                  </Typography>
                </Box>
                <Box display="flex" flexDirection="column" alignItems="center">
                  <EmailOutlinedIcon
                    sx={{
                      fontSize: isSmallScreen ? 40 : 60, // Adjust icon size based on screen size
                      color: "black",
                    }}
                  />
                  <Typography variant="body2" sx={{ mt: 1 }} color="#b91c1c">
                    smartsales@smartglobalgt.com
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
          <Grid container spacing={2}>
            {/* First Column */}
            <Grid item xs={12} md={6}>
              <Box display="flex" flexDirection="column" height="100%">
                <TextField
                  label="Name*"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  name="name"
                  value={formData.name}
                  InputLabelProps={{
                    style: { top: "-6px" }, // Adjust these values as needed
                  }}
                  onChange={handleChange}
                  sx={{
                    marginBottom: 1,
                  }}
                />
                <TextField
                  label="Email*"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  type="email"
                  name="email"
                  value={formData.email}
                  InputLabelProps={{
                    style: { top: "-6px" }, // Adjust these values as needed
                  }}
                  onChange={handleChange}
                  sx={{
                    marginBottom: 1,
                  }}
                  // Adds spacing between text fields
                />
                <TextField
                  label="Phone*"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  type="tel"
                  name="contact"
                  value={formData.contact}
                  InputLabelProps={{
                    style: { top: "-6px" }, // Adjust these values as needed
                  }}
                  onChange={handleChange}
                />
              </Box>
            </Grid>

            {/* Second Column */}
            <Grid item xs={12} md={6}>
              <Box display="flex" flexDirection="column" height="100%">
                <TextField
                  label="Message"
                  variant="outlined"
                  fullWidth
                  multiline
                  rows={5}
                  margin="normal"
                  name="message"
                  value={formData.message}
                  InputLabelProps={{
                    style: { top: "-6px" }, // Adjust these values as needed
                  }}
                  onChange={handleChange}
                  sx={{
                    height: "100%",
                  }} // Ensures it takes up remaining height
                />
              </Box>
            </Grid>
          </Grid>

          {/* Centered Submit Button */}
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            marginTop="12px"
          >
            <Button
              size="small"
              component="a"
              onClick={handleSubmit}
              sx={{
                backgroundColor: "#000", // Black background
                color: "#fff", // White text
                borderRadius: 2, // Squared corners
                "&:hover": {
                  backgroundColor: "#b91c1c", // White background on hover
                  color: "#fff", // Black text on hover
                },
                padding: "6px 12px", // Adjust padding if needed
                textTransform: "none", // Prevent uppercase transformation
              }}
            >
              Submit{" "}
              <svg
                class="rtl:rotate-180 w-3.5 h-3.5 ms-2"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 14 10"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M1 5h12m0 0L9 1m4 4L9 9"
                />
              </svg>
            </Button>
            {/* <Button
              variant="contained"
              color="primary"
              type="submit"
              onClick={handleSubmit}
            >
              Submit
            </Button> */}
            {/* <p style={{ color: "black" }}>OR</p>
            <Button
              size="small"
              component="a"
              onClick={() => {
                window.open("https://wa.me/message/E6ISHYZGBBKAE1", "_blank");
              }}
              sx={{
                backgroundColor: "#075e54", // Black background
                color: "#fff", // White text
                borderRadius: 2, // Squared corners
                "&:hover": {
                  backgroundColor: "#000", // White background on hover
                  color: "#fff", // Black text on hover
                },
                padding: "6px 12px", // Adjust padding if needed
                textTransform: "none", // Prevent uppercase transformation
              }}
            >
              <WhatsAppIcon
                sx={{
                  color: "white",
                  marginRight: "2px",
                  fontSize: "20px",
                }}
              />
              WhatsApp Us!{" "}
            </Button> */}
            {/* <Button
              variant="contained"
              type="submit"
              onClick={() => {
                window.open("https://wa.me/message/E6ISHYZGBBKAE1", "_blank");
              }}
              sx={{ background: "#075e54" }}
            >
              <WhatsAppIcon
                sx={{
                  color: "white",
                  marginRight: "2px",
                }}
              />
              WhatsApp Us!
            </Button> */}
          </Box>
        </Container>
      </Box>
    </div>
  );
};

export default GetInTouch;
