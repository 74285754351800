// data.js

const brands = [
  { name: "3COM", image: "/assets/brands/3COM.jpeg" },
  { name: "3M", image: "/assets/brands/3M.png" },
  { name: "ABB", image: "/assets/brands/ABB.png" },
  { name: "AccuStar", image: "/assets/brands/AccuStar.png" },
  { name: "Acrel", image: "/assets/brands/Acrel.jpeg" },
  { name: "Adafruit", image: "/assets/brands/Adafruit.jpeg" },
  { name: "ADEL", image: "/assets/brands/ADEL.png" },
  { name: "AEROVOX", image: "/assets/brands/AEROVOX.jpeg" },
  { name: "AGASTAT", image: "/assets/brands/AGASTAT.png" },
  {
    name: "Agilent_Technologies",
    image: "/assets/brands/Agilent_Technologies.jpeg",
  },
  { name: "AIM_TTI", image: "/assets/brands/AIM_TTI.jpeg" },
  { name: "AIRTEC", image: "/assets/brands/AIRTEC.jpeg" },
  { name: "Aleph", image: "/assets/brands/Aleph.png" },
  { name: "ALFA_LAVAL", image: "/assets/brands/ALFA_LAVAL.png" },
  { name: "ALLEN_BRADLEY", image: "/assets/brands/ALLEN_BRADLEY.png" },
  { name: "ALLIANCE", image: "/assets/brands/ALLIANCE.png" },
  { name: "AMEC", image: "/assets/brands/AMEC.png" },
  { name: "AMPHENOL", image: "/assets/brands/AMPHENOL.png" },
  { name: "AMPROBE", image: "/assets/brands/AMPROBE.png" },
  { name: "Analog_Devices", image: "/assets/brands/Analog_Devices.png" },
  { name: "ANHUI_TONGFENG", image: "/assets/brands/ANHUI_TONGFENG.jpeg" },
  { name: "ANLY", image: "/assets/brands/ANLY.png" },
  { name: "Aotoro", image: "/assets/brands/Aotoro.png" },
  { name: "APEM", image: "/assets/brands/APEM.jpeg" },
  { name: "APPLETON", image: "/assets/brands/APPLETON.png" },
  { name: "Applied_Kilovolts", image: "/assets/brands/Applied_Kilovolts.png" },
  { name: "Aptiv", image: "/assets/brands/Aptiv.png" },
  { name: "ARCO", image: "/assets/brands/ARCO.png" },
  { name: "ARCOL", image: "/assets/brands/ARCOL.png" },
  { name: "ARDITI", image: "/assets/brands/ARDITI.png" },
  { name: "Arduino", image: "/assets/brands/Arduino.png" },
  { name: "ARTESYN_EMBEDDED", image: "/assets/brands/ARTESYN_EMBEDDED.png" },
  { name: "ASCO_POWER", image: "/assets/brands/ASCO_POWER.png" },
  { name: "ASCON", image: "/assets/brands/ASCON.png" },
  { name: "ASIAON", image: "/assets/brands/ASIAON.png" },
  { name: "Astrosyn", image: "/assets/brands/Astrosyn.jpeg" },
  { name: "ATMEL", image: "/assets/brands/ATMEL.png" },
  { name: "AUTO_TRANSFORMER", image: "/assets/brands/AUTO_TRANSFORMER.png" },
  { name: "AUTONICS", image: "/assets/brands/AUTONICS.png" },
  { name: "AVX", image: "/assets/brands/AVX.png" },
  { name: "Azbil", image: "/assets/brands/Azbil.jpeg" },
  { name: "BACKHOFF", image: "/assets/brands/BACKHOFF.png" },
  { name: "BACO", image: "/assets/brands/BACO.png" },
  { name: "BAKER", image: "/assets/brands/BAKER.png" },
  { name: "BALLUFF", image: "/assets/brands/BALLUFF.png" },
  { name: "BANNER", image: "/assets/brands/BANNER.png" },
  { name: "BAUMER", image: "/assets/brands/BAUMER.jpeg" },
  { name: "BECKHOFF", image: "/assets/brands/BECKHOFF.png" },
  { name: "BEL_FUSE_INC", image: "/assets/brands/BEL_FUSE_INC.jpeg" },
  { name: "BELIMO", image: "/assets/brands/BELIMO.png" },
  { name: "BENTLY_NEVADA", image: "/assets/brands/BENTLY_NEVADA.png" },
  { name: "BERNSTEIN", image: "/assets/brands/BERNSTEIN.png" },
  { name: "Beta", image: "/assets/brands/Beta.png" },
  { name: "BK_Precision", image: "/assets/brands/BK_Precision.jpeg" },
  { name: "BOSCH", image: "/assets/brands/BOSCH.png" },
  { name: "BOSMA_CONTROLS", image: "/assets/brands/BOSMA_CONTROLS.png" },
  { name: "BOURNS", image: "/assets/brands/BOURNS.png" },
  { name: "BPC_ENRGY", image: "/assets/brands/BPC_ENRGY.png" },
  { name: "BROYCE_CONTROL", image: "/assets/brands/BROYCE_CONTROL.jpeg" },
  { name: "Bulgin", image: "/assets/brands/Bulgin.jpeg" },
  { name: "BUSSMANN", image: "/assets/brands/BUSSMANN.png" },
  { name: "CABUR", image: "/assets/brands/CABUR.png" },
  { name: "CALTER", image: "/assets/brands/CALTER.jpeg" },
  { name: "CAMILLE_BAUER", image: "/assets/brands/CAMILLE_BAUER.jpeg" },
  { name: "CAREL", image: "/assets/brands/CAREL.png" },
  { name: "CARLO_GAVAZZI", image: "/assets/brands/CARLO_GAVAZZI.png" },
  { name: "CASAL", image: "/assets/brands/CASAL.png" },
  { name: "CEAG", image: "/assets/brands/CEAG.png" },
  { name: "CELDUC", image: "/assets/brands/CELDUC.jpeg" },
  { name: "CELESCO", image: "/assets/brands/CELESCO.jpeg" },
  { name: "CERATIZIT", image: "/assets/brands/CERATIZIT.png" },
  { name: "CHELIC", image: "/assets/brands/CHELIC.jpeg" },
  { name: "CHERRY", image: "/assets/brands/CHERRY.png" },
  { name: "CHG", image: "/assets/brands/CHG.png" },
  { name: "CHIA_YU_ELECTRIC", image: "/assets/brands/smartgtlogo.jpeg" },
  { name: "CHINT", image: "/assets/brands/CHINT.png" },
  { name: "CHNT", image: "/assets/brands/CHNT.png" },
  {
    name: "CINCON_ELECTRONICS",
    image: "/assets/brands/CINCON_ELECTRONICS.png",
  },
  { name: "CKC", image: "/assets/brands/CKC.png" },
  { name: "COAX", image: "/assets/brands/COAX.png" },
  { name: "COGNEX", image: "/assets/brands/COGNEX.png" },
  { name: "Cogsdill", image: "/assets/brands/Cogsdill.png" },
  { name: "Comar", image: "/assets/brands/Comar.png" },
  { name: "COMMONWEALTH", image: "/assets/brands/COMMONWEALTH.png" },
  { name: "CONTRINEX", image: "/assets/brands/CONTRINEX.png" },
  { name: "CORNELL_DUBILIER", image: "/assets/brands/CORNELL_DUBILIER.jpeg" },
  { name: "Corning", image: "/assets/brands/Corning.png" },
  { name: "COSEL", image: "/assets/brands/COSEL.png" },
  { name: "CR_MAGNETICS", image: "/assets/brands/CR_MAGNETICS.jpeg" },
  { name: "CROUSE_HINDS", image: "/assets/brands/CROUSE_HINDS.jpeg" },
  { name: "CROUZET", image: "/assets/brands/CROUZET.png" },
  { name: "CRYDOM", image: "/assets/brands/CRYDOM.png" },
  { name: "CSB_Battery", image: "/assets/brands/CSB_Battery.png" },
  { name: "D-LINK", image: "/assets/brands/D-LINK.png" },
  { name: "DALLO", image: "/assets/brands/smartgtlogo.jpeg" },
  { name: "DANFOSS", image: "/assets/brands/DANFOSS.png" },
  { name: "DATALOGIC", image: "/assets/brands/DATALOGIC.png" },
  { name: "DATASENSOR", image: "/assets/brands/DATASENSOR.png" },
  { name: "DELIXI", image: "/assets/brands/DELIXI.png" },
  { name: "Dell", image: "/assets/brands/Dell.jpeg" },
  { name: "DELTA_Electronics", image: "/assets/brands/DELTA_Electronics.png" },
  { name: "DELTA_Electronics", image: "/assets/brands/DELTA_Electronics.png" },
  { name: "DENSI", image: "/assets/brands/DENSI.png" },
  { name: "Diodes", image: "/assets/brands/Diodes.png" },
  { name: "DIOTEC", image: "/assets/brands/DIOTEC.jpeg" },
  { name: "DOLD&SOEHNE", image: "/assets/brands/DOLD&SOEHNE.jpeg" },
  { name: "DORMER", image: "/assets/brands/DORMER.jpeg" },
  { name: "DRUKE", image: "/assets/brands/DRUKE.jpeg" },
  { name: "DUCATI", image: "/assets/brands/DUCATI.png" },
  { name: "DYNALCO", image: "/assets/brands/DYNALCO.png" },
  { name: "Dynapar", image: "/assets/brands/Dynapar.png" },
  { name: "E+E_ELEKTRONIK", image: "/assets/brands/E+E_ELEKTRONIK.jpeg" },
  { name: "EAO", image: "/assets/brands/EAO.png" },
  { name: "EATON", image: "/assets/brands/EATON.jpeg" },
  { name: "EBM-PAPST", image: "/assets/brands/EBM-PAPST.png" },
  { name: "ECOFIT", image: "/assets/brands/ECOFIT.png" },
  { name: "EE_TOOLS", image: "/assets/brands/EE_TOOLS.jpeg" },
  { name: "Elation", image: "/assets/brands/Elation.png" },
  { name: "ELCA", image: "/assets/brands/ELCA.png" },
  { name: "ELIWELL", image: "/assets/brands/ELIWELL.png" },
  { name: "EMBRACO", image: "/assets/brands/EMBRACO.png" },
  { name: "EMC", image: "/assets/brands/smartgtlogo.jpeg" },
  { name: "EMERSON", image: "/assets/brands/EMERSON.png" },
  { name: "Emhiser_Research", image: "/assets/brands/Emhiser_Research.jpeg" },
  { name: "ENDRESS+HAUSER", image: "/assets/brands/ENDRESS+HAUSER.jpeg" },
  { name: "ENHANCE", image: "/assets/brands/ENHANCE.png" },
  { name: "EPCOS", image: "/assets/brands/EPCOS.png" },
  { name: "ERI", image: "/assets/brands/ERI.png" },
  { name: "ERIFLEX", image: "/assets/brands/ERIFLEX.png" },
  { name: "ESCHA", image: "/assets/brands/ESCHA.png" },
  { name: "ETA", image: "/assets/brands/ETA.jpeg" },
  { name: "ETI", image: "/assets/brands/ETI.png" },
  { name: "ETRI", image: "/assets/brands/ETRI.jpeg" },
  { name: "EUCHNER", image: "/assets/brands/EUCHNER.png" },
  { name: "EUPEC", image: "/assets/brands/EUPEC.jpeg" },
  { name: "EUROPA_TOOL", image: "/assets/brands/EUROPA_TOOL.png" },
  { name: "EXAR", image: "/assets/brands/EXAR.png" },
  { name: "EXCELITAS", image: "/assets/brands/EXCELITAS.png" },
  { name: "EXTECH", image: "/assets/brands/EXTECH.png" },
  { name: "FAGOR", image: "/assets/brands/FAGOR.png" },
  { name: "FAIRCHILD", image: "/assets/brands/FAIRCHILD.jpeg" },
  { name: "Fandis", image: "/assets/brands/Fandis.png" },
  { name: "FANTINI-COSMI", image: "/assets/brands/FANTINI-COSMI.jpeg" },
  { name: "FANTINICOSMI", image: "/assets/brands/FANTINICOSMI.jpeg" },
  { name: "FAR_EUROPE", image: "/assets/brands/smartgtlogo.jpeg" },
  { name: "FASE", image: "/assets/brands/smartgtlogo.jpeg" },
  { name: "FDK", image: "/assets/brands/FDK.png" },
  { name: "FERRAZ_SHAWMUT", image: "/assets/brands/FERRAZ_SHAWMUT.png" },
  { name: "FESTO", image: "/assets/brands/FESTO.png" },
  { name: "FINDER", image: "/assets/brands/FINDER.png" },
  { name: "FINE_SUNTRONIX", image: "/assets/brands/FINE_SUNTRONIX.png" },
  { name: "FLIR", image: "/assets/brands/FLIR.png" },
  { name: "FLUKE", image: "/assets/brands/FLUKE.png" },
  { name: "FORMAT", image: "/assets/brands/FORMAT.jpeg" },
  { name: "Fortress", image: "/assets/brands/Fortress.jpeg" },
  { name: "FOTEK", image: "/assets/brands/FOTEK.png" },
  { name: "FOX", image: "/assets/brands/FOX.jpeg" },
  { name: "FOXBORO", image: "/assets/brands/FOXBORO.png" },
  { name: "FOXTAM", image: "/assets/brands/FOXTAM.png" },
  { name: "FUJI_ELECTRIC", image: "/assets/brands/FUJI_ELECTRIC.png" },
  { name: "FUJIKURA", image: "/assets/brands/FUJIKURA.png" },
  { name: "FUJISTSU", image: "/assets/brands/FUJISTSU.png" },
  { name: "FULLTECH_ELECTRIC", image: "/assets/brands/FULLTECH_ELECTRIC.jpeg" },
  {
    name: "G.M._International",
    image: "/assets/brands/G.M._International.png",
  },
  { name: "Gas_Clip", image: "/assets/brands/Gas_Clip.png" },
  { name: "GE_FANUC", image: "/assets/brands/GE_FANUC.png" },
  { name: "GE_Security", image: "/assets/brands/smartgtlogo.jpeg" },
  { name: "GEMS", image: "/assets/brands/GEMS.png" },
  { name: "GENERAL_ELECTRIC", image: "/assets/brands/GENERAL_ELECTRIC.png" },
  { name: "GENTEQ", image: "/assets/brands/GENTEQ.jpeg" },
  { name: "Genuine_GM", image: "/assets/brands/Genuine_GM.png" },
  { name: "GHURING", image: "/assets/brands/GHURING.jpeg" },
  { name: "GIC", image: "/assets/brands/GIC.png" },
  { name: "GO", image: "/assets/brands/GO.png" },
  { name: "GOLDTOOL", image: "/assets/brands/smartgtlogo.jpeg" },
  { name: "GOLINK", image: "/assets/brands/smartgtlogo.jpeg" },
  { name: "GRAINGER", image: "/assets/brands/GRAINGER.jpeg" },
  { name: "Greenleaf", image: "/assets/brands/Greenleaf.jpeg" },
  { name: "GREENLEE", image: "/assets/brands/GREENLEE.png" },
  { name: "GW_Instek", image: "/assets/brands/GW_Instek.png" },
  { name: "HAGER", image: "/assets/brands/HAGER.png" },
  { name: "HAKKO", image: "/assets/brands/HAKKO.png" },
  { name: "HAMEG", image: "/assets/brands/HAMEG.jpeg" },
  { name: "HAMILTON", image: "/assets/brands/HAMILTON.jpeg" },
  { name: "HANTEK", image: "/assets/brands/HANTEK.jpeg" },
  { name: "HANYOUNG_NUX", image: "/assets/brands/HANYOUNG_NUX.png" },
  { name: "HARTING", image: "/assets/brands/HARTING.png" },
  { name: "Hawke", image: "/assets/brands/Hawke.png" },
  { name: "HEIDENHAIN", image: "/assets/brands/HEIDENHAIN.png" },
  { name: "HEKA-Brutgerate", image: "/assets/brands/HEKA-Brutgerate.png" },
  { name: "Hicon", image: "/assets/brands/smartgtlogo.jpeg" },
  { name: "HIDRIA", image: "/assets/brands/HIDRIA.jpeg" },
  { name: "HIGHLY", image: "/assets/brands/smartgtlogo.jpeg" },
  { name: "HIOKI", image: "/assets/brands/HIOKI.png" },
  { name: "HIQUEL", image: "/assets/brands/HIQUEL.png" },
  { name: "HIRSCHMANN", image: "/assets/brands/HIRSCHMANN.png" },
  { name: "HITACHI", image: "/assets/brands/HITACHI.png" },
  { name: "HITACHI_Chemical", image: "/assets/brands/HITACHI_Chemical.png" },
  { name: "HOBUT", image: "/assets/brands/HOBUT.png" },
  { name: "HOLTEK", image: "/assets/brands/HOLTEK.png" },
  { name: "HOMER", image: "/assets/brands/smartgtlogo.jpeg" },
  { name: "Honeywell", image: "/assets/brands/Honeywell.png" },
  { name: "HP", image: "/assets/brands/HP.png" },
  { name: "Hubbell-Killark", image: "/assets/brands/Hubbell-Killark.png" },
  { name: "HYDAC", image: "/assets/brands/HYDAC.png" },
  { name: "HYUNDAI", image: "/assets/brands/HYUNDAI.jpeg" },
  { name: "ICAR", image: "/assets/brands/smartgtlogo.jpeg" },
  { name: "IDEC", image: "/assets/brands/IDEC.png" },
  { name: "IFM", image: "/assets/brands/IFM.jpeg" },
  { name: "IMADA", image: "/assets/brands/IMADA.png" },
  { name: "IMO", image: "/assets/brands/IMO.jpeg" },
  { name: "INFINEON", image: "/assets/brands/INFINEON.png" },
  {
    name: "INTERNATIONAL_RECTIFIER",
    image: "/assets/brands/INTERNATIONAL_RECTIFIER.jpeg",
  },
  { name: "ISCAR", image: "/assets/brands/ISCAR.png" },
  { name: "ISD", image: "/assets/brands/smartgtlogo.jpeg" },
  { name: "ISO-TECH", image: "/assets/brands/ISO-TECH.jpeg" },
  { name: "ITALWEBER", image: "/assets/brands/ITALWEBER.jpeg" },
  { name: "ITT_CANNON", image: "/assets/brands/ITT_CANNON.jpeg" },
  { name: "IXYS", image: "/assets/brands/IXYS.png" },
  { name: "JEAN_MULLER", image: "/assets/brands/JEAN_MULLER.png" },
  { name: "Johnson_Controls", image: "/assets/brands/Johnson_Controls.png" },
  { name: "JONARD", image: "/assets/brands/JONARD.jpeg" },
  { name: "Jovvie", image: "/assets/brands/Jovvie.png" },
  { name: "JRC", image: "/assets/brands/smartgtlogo.jpeg" },
  { name: "JST", image: "/assets/brands/JST.png" },
  { name: "JUCHE", image: "/assets/brands/smartgtlogo.jpeg" },
  { name: "JUMO", image: "/assets/brands/JUMO.png" },
  { name: "KDK", image: "/assets/brands/KDK.png" },
  { name: "Keep", image: "/assets/brands/smartgtlogo.jpeg" },
  { name: "KEITHLEY", image: "/assets/brands/KEITHLEY.png" },
  { name: "KEMET", image: "/assets/brands/KEMET.png" },
  { name: "Kendeil", image: "/assets/brands/Kendeil.jpeg" },
  { name: "KENNAMETAL", image: "/assets/brands/KENNAMETAL.jpeg" },
  { name: "KEYENCE", image: "/assets/brands/KEYENCE.png" },
  { name: "KEYSIGHT", image: "/assets/brands/KEYSIGHT.jpeg" },
  { name: "KIATRONICS", image: "/assets/brands/KIATRONICS.jpeg" },
  { name: "Kitagawa", image: "/assets/brands/Kitagawa.png" },
  { name: "KITEK", image: "/assets/brands/smartgtlogo.jpeg" },
  { name: "KLINGER", image: "/assets/brands/KLINGER.png" },
  { name: "KOBOLD", image: "/assets/brands/KOBOLD.png" },
  { name: "KOGANEI", image: "/assets/brands/KOGANEI.jpeg" },
  { name: "KOINO", image: "/assets/brands/KOINO.jpeg" },
  { name: "KOMATSU", image: "/assets/brands/KOMATSU.png" },
  { name: "KOYO", image: "/assets/brands/KOYO.png" },
  { name: "KUBLER", image: "/assets/brands/KUBLER.jpeg" },
  { name: "KUHNKE", image: "/assets/brands/KUHNKE.jpeg" },
  { name: "KYOCERA", image: "/assets/brands/KYOCERA.jpeg" },
  { name: "KYORITSU", image: "/assets/brands/KYORITSU.png" },
  { name: "LAMBDA", image: "/assets/brands/smartgtlogo.jpeg" },
  { name: "LAPP_KABEL", image: "/assets/brands/LAPP_KABEL.png" },
  { name: "LARSEN_&_TOUBRO", image: "/assets/brands/LARSEN_&_TOUBRO.png" },
  {
    name: "Lattice_Semiconductor",
    image: "/assets/brands/Lattice_Semiconductor.jpeg",
  },
  { name: "Leadshine", image: "/assets/brands/Leadshine.png" },
  { name: "LEFOO", image: "/assets/brands/LEFOO.jpeg" },
  { name: "LEGRAND", image: "/assets/brands/LEGRAND.jpeg" },
  { name: "LEINE_LINDE", image: "/assets/brands/LEINE_LINDE.png" },
  { name: "LEIPOLD", image: "/assets/brands/smartgtlogo.jpeg" },
  { name: "LEM_USA", image: "/assets/brands/smartgtlogo.jpeg" },
  { name: "LENZE", image: "/assets/brands/LENZE.png" },
  { name: "Leroy_Somer", image: "/assets/brands/Leroy_Somer.jpeg" },
  { name: "LEUZE_ELECTRONIC", image: "/assets/brands/LEUZE_ELECTRONIC.png" },
  { name: "LIFUD", image: "/assets/brands/smartgtlogo.jpeg" },
  { name: "Lincoln", image: "/assets/brands/Lincoln.png" },
  { name: "Lirrd", image: "/assets/brands/Lirrd.jpeg" },
  { name: "Littelfuse", image: "/assets/brands/Littelfuse.jpeg" },
  { name: "LOVATO_ELECTRIC", image: "/assets/brands/LOVATO_ELECTRIC.png" },
  { name: "LS", image: "/assets/brands/LS.png" },
  { name: "LTD.", image: "/assets/brands/LTD..png" },
  { name: "LUTRON", image: "/assets/brands/LUTRON.png" },
  { name: "M-SYSTEM", image: "/assets/brands/M-SYSTEM.png" },
  { name: "M.A._FORD", image: "/assets/brands/M.A._FORD.png" },
  { name: "M.E.C._Relays", image: "/assets/brands/M.E.C._Relays.jpeg" },
  { name: "MACHINE_ADDA", image: "/assets/brands/MACHINE_ADDA.png" },
  { name: "Mager", image: "/assets/brands/smartgtlogo.jpeg" },
  { name: "MAHR", image: "/assets/brands/MAHR.png" },
  { name: "MAMAC", image: "/assets/brands/smartgtlogo.jpeg" },
  { name: "MARLIN", image: "/assets/brands/MARLIN.jpeg" },
  { name: "MAXIM_INTEGRATED", image: "/assets/brands/MAXIM_INTEGRATED.png" },
  { name: "MEAN_WELL", image: "/assets/brands/MEAN_WELL.png" },
  { name: "MEGGER", image: "/assets/brands/MEGGER.png" },
  { name: "MERSEN", image: "/assets/brands/MERSEN.jpeg" },
  { name: "METASOL", image: "/assets/brands/METASOL.png" },
  { name: "Metrel", image: "/assets/brands/Metrel.png" },
  { name: "Mettler_Toledo", image: "/assets/brands/Mettler_Toledo.jpeg" },
  { name: "MICROCHIP", image: "/assets/brands/MICROCHIP.png" },
  { name: "Micronics", image: "/assets/brands/Micronics.png" },
  { name: "MICROSONIC", image: "/assets/brands/MICROSONIC.png" },
  { name: "MINI-CIRCUITS", image: "/assets/brands/MINI-CIRCUITS.jpeg" },
  { name: "MITL", image: "/assets/brands/MITL.png" },
  { name: "MITSUBISHI", image: "/assets/brands/MITSUBISHI.png" },
  { name: "MITSUBISHI_MOTORS", image: "/assets/brands/MITSUBISHI_MOTORS.png" },
  { name: "MITUTOYO", image: "/assets/brands/MITUTOYO.png" },
  { name: "MK", image: "/assets/brands/MK.png" },
  {
    name: "MK_Electric_By_Honeywell",
    image: "/assets/brands/MK_Electric_By_Honeywell.jpeg",
  },
  { name: "MKS_TECHNOLOGY", image: "/assets/brands/MKS_TECHNOLOGY.png" },
  { name: "MODEION", image: "/assets/brands/MODEION.png" },
  { name: "MOELLER", image: "/assets/brands/MOELLER.png" },
  { name: "MOLEX", image: "/assets/brands/MOLEX.png" },
  { name: "MONDEO", image: "/assets/brands/MONDEO.png" },
  { name: "MOORE", image: "/assets/brands/MOORE.png" },
  { name: "MOXA", image: "/assets/brands/MOXA.png" },
  { name: "MP_FILTRI", image: "/assets/brands/MP_FILTRI.png" },
  { name: "MR", image: "/assets/brands/MR.jpeg" },
  { name: "MTL_INSTRUMENTS", image: "/assets/brands/MTL_INSTRUMENTS.jpeg" },
  { name: "MULTICOMAT", image: "/assets/brands/MULTICOMAT.jpeg" },
  { name: "MULTICOMP", image: "/assets/brands/MULTICOMP.png" },
  { name: "Murphy", image: "/assets/brands/Murphy.png" },
  { name: "MURR_ELEKTRONIK", image: "/assets/brands/MURR_ELEKTRONIK.png" },
  { name: "MYRRA", image: "/assets/brands/MYRRA.png" },
  { name: "NAGARES", image: "/assets/brands/NAGARES.png" },
  { name: "NEC", image: "/assets/brands/NEC.png" },
  { name: "NEMICON_CORP", image: "/assets/brands/NEMICON_CORP.png" },
  { name: "Nexperia", image: "/assets/brands/Nexperia.png" },
  { name: "NFC", image: "/assets/brands/smartgtlogo.jpeg" },
  { name: "NICHICON", image: "/assets/brands/NICHICON.jpeg" },
  { name: "NIDEC", image: "/assets/brands/NIDEC.jpeg" },
  { name: "NKK", image: "/assets/brands/NKK.jpeg" },
  { name: "NKK_SWITCHES", image: "/assets/brands/NKK_SWITCHES.jpeg" },
  { name: "NMB", image: "/assets/brands/NMB.jpeg" },
  { name: "NOBEL", image: "/assets/brands/NOBEL.png" },
  { name: "NONOI", image: "/assets/brands/smartgtlogo.jpeg" },
  { name: "NORATEL", image: "/assets/brands/NORATEL.png" },
  { name: "NORGREN", image: "/assets/brands/NORGREN.png" },
  { name: "NOVO_TECHNIK", image: "/assets/brands/NOVO_TECHNIK.png" },
  { name: "Novotechnik", image: "/assets/brands/Novotechnik.png" },
  { name: "NVENT", image: "/assets/brands/NVENT.png" },
  { name: "NXP", image: "/assets/brands/NXP.png" },
  { name: "OMEGA", image: "/assets/brands/OMEGA.png" },
  { name: "Omron", image: "/assets/brands/Omron.png" },
  { name: "ON_SEMICONDUCTOR", image: "/assets/brands/ON_SEMICONDUCTOR.jpeg" },
  { name: "OPKON", image: "/assets/brands/OPKON.jpeg" },
  { name: "Oriental_Motor", image: "/assets/brands/Oriental_Motor.png" },
  { name: "OT_SYSTEMS", image: "/assets/brands/OT_SYSTEMS.png" },
  { name: "OWON", image: "/assets/brands/OWON.jpeg" },
  { name: "Paladin_Tools", image: "/assets/brands/Paladin_Tools.png" },
  { name: "PALAZZOLI", image: "/assets/brands/PALAZZOLI.jpeg" },
  { name: "PANAFLO", image: "/assets/brands/smartgtlogo.jpeg" },
  { name: "PANASONIC", image: "/assets/brands/PANASONIC.png" },
  { name: "PARKER", image: "/assets/brands/PARKER.png" },
  { name: "Parmak", image: "/assets/brands/smartgtlogo.jpeg" },
  { name: "PEPPERL_FUCHS", image: "/assets/brands/PEPPERL_FUCHS.jpeg" },
  { name: "PEPPERL+FUCHS", image: "/assets/brands/PEPPERL+FUCHS.jpeg" },
  { name: "Perry", image: "/assets/brands/Perry.png" },
  { name: "Pfannenberg", image: "/assets/brands/Pfannenberg.png" },
  { name: "PH_HORN", image: "/assets/brands/PH_HORN.png" },
  { name: "PHILIPS", image: "/assets/brands/PHILIPS.png" },
  { name: "PHILIPS_BODINE", image: "/assets/brands/PHILIPS_BODINE.png" },
  { name: "PHOENIX_CONTACT", image: "/assets/brands/PHOENIX_CONTACT.png" },
  { name: "PI", image: "/assets/brands/PI.png" },
  { name: "Piezodrive", image: "/assets/brands/smartgtlogo.jpeg" },
  { name: "Pilz", image: "/assets/brands/Pilz.png" },
  { name: "Pizzato", image: "/assets/brands/Pizzato.png" },
  { name: "PNEUMAX", image: "/assets/brands/PNEUMAX.jpeg" },
  { name: "POLYTRON", image: "/assets/brands/POLYTRON.png" },
  { name: "POMONA", image: "/assets/brands/POMONA.jpeg" },
  { name: "POTTER&_BRUMFIELD", image: "/assets/brands/POTTER&_BRUMFIELD.png" },
  { name: "POTTER&BRUMFIELD", image: "/assets/brands/POTTER&BRUMFIELD.png" },
  { name: "POWERBUILT", image: "/assets/brands/POWERBUILT.png" },
  { name: "POWEREX", image: "/assets/brands/POWEREX.png" },
  { name: "PR_ELECTRONICS", image: "/assets/brands/PR_ELECTRONICS.png" },
  { name: "Precision", image: "/assets/brands/Precision.png" },
  { name: "Pro'skit", image: "/assets/brands/Pro'skit.jpeg" },
  { name: "PUDENZ", image: "/assets/brands/PUDENZ.jpeg" },
  { name: "PULS", image: "/assets/brands/PULS.png" },
  { name: "QUEST", image: "/assets/brands/QUEST.jpeg" },
  { name: "Raspberry_Pi", image: "/assets/brands/Raspberry_Pi.png" },
  { name: "RAYTEK", image: "/assets/brands/RAYTEK.jpeg" },
  { name: "REACH", image: "/assets/brands/REACH.png" },
  { name: "ReeR", image: "/assets/brands/ReeR.png" },
  { name: "ReignPower", image: "/assets/brands/ReignPower.jpeg" },
  { name: "RELECO", image: "/assets/brands/RELECO.png" },
  { name: "RELPOL_S.A.", image: "/assets/brands/RELPOL_S.A..jpeg" },
  { name: "REXROTH", image: "/assets/brands/REXROTH.jpeg" },
  { name: "RIESE", image: "/assets/brands/RIESE.png" },
  { name: "RIFA", image: "/assets/brands/RIFA.png" },
  { name: "RIGOL", image: "/assets/brands/RIGOL.png" },
  { name: "RIKO", image: "/assets/brands/RIKO.png" },
  { name: "RITTAL", image: "/assets/brands/RITTAL.jpeg" },
  {
    name: "Rockwell_Automation",
    image: "/assets/brands/Rockwell_Automation.jpeg",
  },
  { name: "ROPENTE", image: "/assets/brands/ROPENTE.png" },
  { name: "ROSEMOUNT", image: "/assets/brands/ROSEMOUNT.jpeg" },
  { name: "ROSENBERG", image: "/assets/brands/ROSENBERG.png" },
  { name: "RS_Pro", image: "/assets/brands/RS_Pro.png" },
  { name: "Rubsamen_&_Herr", image: "/assets/brands/Rubsamen_&_Herr.png" },
  { name: "RUGGEDCOM", image: "/assets/brands/RUGGEDCOM.jpeg" },
  { name: "SAFT", image: "/assets/brands/SAFT.png" },
  { name: "SAKAE", image: "/assets/brands/smartgtlogo.jpeg" },
  { name: "SAMLEX", image: "/assets/brands/SAMLEX.png" },
  { name: "SanDisk", image: "/assets/brands/SanDisk.jpeg" },
  { name: "Sandvik", image: "/assets/brands/Sandvik.jpeg" },
  { name: "SANDVIK_COROMANT", image: "/assets/brands/SANDVIK_COROMANT.jpeg" },
  { name: "SANREX", image: "/assets/brands/SANREX.jpeg" },
  { name: "SANYO", image: "/assets/brands/SANYO.png" },
  { name: "SANYO_DENKI", image: "/assets/brands/SANYO_DENKI.png" },
  { name: "Satronic", image: "/assets/brands/Satronic.jpeg" },
  { name: "SCHMERSAL", image: "/assets/brands/SCHMERSAL.png" },
  { name: "SCHNEIDER", image: "/assets/brands/SCHNEIDER.png" },
  { name: "SCHRACK", image: "/assets/brands/SCHRACK.jpeg" },
  { name: "SECO", image: "/assets/brands/SECO.png" },
  { name: "Seeed", image: "/assets/brands/Seeed.png" },
  { name: "SELEC", image: "/assets/brands/SELEC.png" },
  { name: "SEMIKRON", image: "/assets/brands/SEMIKRON.png" },
  { name: "SENECA", image: "/assets/brands/smartgtlogo.jpeg" },
  { name: "SENSOPART", image: "/assets/brands/SENSOPART.png" },
  { name: "Sensorex", image: "/assets/brands/Sensorex.png" },
  { name: "SERVOSTAR", image: "/assets/brands/SERVOSTAR.png" },
  { name: "SEW_EURODRIVE", image: "/assets/brands/SEW_EURODRIVE.png" },
  { name: "SHANGHAI_YATAI", image: "/assets/brands/smartgtlogo.jpeg" },
  { name: "SHARP", image: "/assets/brands/SHARP.png" },
  { name: "SHENZHEN", image: "/assets/brands/smartgtlogo.jpeg" },
  {
    name: "Shenzhen_Jian_Zhun",
    image: "/assets/brands/smartgtlogo.jpeg",
  },
  { name: "SHIHLIN", image: "/assets/brands/SHIHLIN.png" },
  { name: "SHINDENGEN", image: "/assets/brands/SHINDENGEN.png" },
  { name: "SHINKO", image: "/assets/brands/smartgtlogo.jpeg" },
  { name: "SIBA", image: "/assets/brands/SIBA.png" },
  { name: "SICCOM", image: "/assets/brands/SICCOM.png" },
  { name: "SICK", image: "/assets/brands/SICK.png" },
  { name: "SIEMENS", image: "/assets/brands/SIEMENS.jpeg" },
  { name: "Sinwan", image: "/assets/brands/smartgtlogo.jpeg" },
  { name: "SkyJack", image: "/assets/brands/SkyJack.png" },
  { name: "SMC", image: "/assets/brands/SMC.png" },
  { name: "Socomec", image: "/assets/brands/Socomec.png" },
  { name: "Sodeca", image: "/assets/brands/Sodeca.jpeg" },
  { name: "SOLER_&PALAU", image: "/assets/brands/SOLER_&PALAU.png" },
  { name: "Song_Chuan", image: "/assets/brands/smartgtlogo.jpeg" },
  { name: "SOUNDER", image: "/assets/brands/smartgtlogo.jpeg" },
  { name: "SPARKFUN", image: "/assets/brands/SPARKFUN.jpeg" },
  { name: "SPIRAX_SARCO", image: "/assets/brands/SPIRAX_SARCO.jpeg" },
  { name: "SPRECHER_+_SCHUH", image: "/assets/brands/SPRECHER_+_SCHUH.jpeg" },
  { name: "SSD", image: "/assets/brands/smartgtlogo.jpeg" },
  { name: "STAHL", image: "/assets/brands/STAHL.png" },
  { name: "STEPPERONLINE", image: "/assets/brands/STEPPERONLINE.png" },
  { name: "STEUTE", image: "/assets/brands/STEUTE.jpeg" },
  {
    name: "STMicroelectronics",
    image: "/assets/brands/STMicroelectronics.jpeg",
  },
  { name: "SUMITOMO", image: "/assets/brands/SUMITOMO.png" },
  { name: "SUNON", image: "/assets/brands/SUNON.jpeg" },
  { name: "SUNS", image: "/assets/brands/smartgtlogo.jpeg" },
  { name: "SUNTRONIX", image: "/assets/brands/SUNTRONIX.png" },
  { name: "Taegutec", image: "/assets/brands/Taegutec.png" },
  {
    name: "TAIWAN_SEMICONDUCTOR",
    image: "/assets/brands/TAIWAN_SEMICONDUCTOR.png",
  },
  { name: "TAKAMISAWA", image: "/assets/brands/TAKAMISAWA.jpeg" },
  { name: "Tauras", image: "/assets/brands/Tauras.png" },
  { name: "TCI", image: "/assets/brands/TCI.jpeg" },
  { name: "TCI_LIGHT", image: "/assets/brands/TCI_LIGHT.jpeg" },
  { name: "TDK", image: "/assets/brands/TDK.png" },
  { name: "TDK-Lambda", image: "/assets/brands/TDK-Lambda.png" },
  { name: "TE_Connectivity", image: "/assets/brands/TE_Connectivity.png" },
  { name: "TECHNO", image: "/assets/brands/TECHNO.jpeg" },
  { name: "TECO", image: "/assets/brands/TECO.jpeg" },
  { name: "TECUMSEH", image: "/assets/brands/TECUMSEH.png" },
  { name: "TEKNIC", image: "/assets/brands/TEKNIC.jpeg" },
  { name: "Teknoware", image: "/assets/brands/Teknoware.jpeg" },
  { name: "TEKTRONIX", image: "/assets/brands/TEKTRONIX.png" },
  { name: "TELCO", image: "/assets/brands/TELCO.jpeg" },
  { name: "TELEMECANIQUE", image: "/assets/brands/TELEMECANIQUE.png" },
  { name: "TELESTAR", image: "/assets/brands/smartgtlogo.jpeg" },
  { name: "TEMPO", image: "/assets/brands/TEMPO.png" },
  { name: "Tend", image: "/assets/brands/Tend.png" },
  { name: "TENMA", image: "/assets/brands/TENMA.png" },
  { name: "Terasaki", image: "/assets/brands/Terasaki.png" },
  { name: "TESTO", image: "/assets/brands/TESTO.jpeg" },
  { name: "TEXAS_INSTRUMENTS", image: "/assets/brands/TEXAS_INSTRUMENTS.png" },
  { name: "THOMSON", image: "/assets/brands/THOMSON.png" },
  {
    name: "TIANJIN_GS_BATTERY",
    image: "/assets/brands/TIANJIN_GS_BATTERY.png",
  },
  { name: "TOPVR", image: "/assets/brands/TOPVR.png" },
  { name: "TOPWORX", image: "/assets/brands/TOPWORX.png" },
  { name: "TORQUE_SYSTEMS", image: "/assets/brands/TORQUE_SYSTEMS.png" },
  { name: "TOSHIBA", image: "/assets/brands/TOSHIBA.png" },
  { name: "TOYO_EZ-Air", image: "/assets/brands/smartgtlogo.jpeg" },
  { name: "TR_ELECTRONIC", image: "/assets/brands/TR_ELECTRONIC.jpeg" },
  { name: "TRACO_POWER", image: "/assets/brands/TRACO_POWER.png" },
  { name: "TRAFAG", image: "/assets/brands/TRAFAG.jpeg" },
  { name: "TRIDONIC", image: "/assets/brands/TRIDONIC.png" },
  { name: "TS1", image: "/assets/brands/TS1.png" },
  { name: "TSI_AIR_FLOW", image: "/assets/brands/TSI_AIR_FLOW.png" },
  { name: "TT_Electronics", image: "/assets/brands/TT_Electronics.png" },
  { name: "Tungaloy", image: "/assets/brands/Tungaloy.jpeg" },
  { name: "TURCK", image: "/assets/brands/TURCK.png" },
  { name: "TURNMAX", image: "/assets/brands/TURNMAX.png" },
  { name: "TYCO_ELECTRONICS", image: "/assets/brands/TYCO_ELECTRONICS.jpeg" },
  { name: "TYCON_POWER", image: "/assets/brands/TYCON_POWER.png" },
  { name: "UNI-T", image: "/assets/brands/UNI-T.png" },
  { name: "UniMeasure", image: "/assets/brands/smartgtlogo.jpeg" },
  { name: "UNITECH", image: "/assets/brands/smartgtlogo.jpeg" },
  { name: "UNITORQ", image: "/assets/brands/UNITORQ.png" },
  { name: "UTC", image: "/assets/brands/UTC.png" },
  { name: "Vandurit", image: "/assets/brands/Vandurit.jpeg" },
  { name: "VERTEX", image: "/assets/brands/VERTEX.jpeg" },
  { name: "VICOR", image: "/assets/brands/VICOR.jpeg" },
  { name: "VICTRON_ENERGY", image: "/assets/brands/VICTRON_ENERGY.png" },
  { name: "VISHAY", image: "/assets/brands/VISHAY.png" },
  { name: "VOKEL", image: "/assets/brands/VOKEL.jpeg" },
  { name: "WAGO", image: "/assets/brands/WAGO.jpeg" },
  { name: "WALLIS", image: "/assets/brands/WALLIS.jpeg" },
  { name: "WALTER", image: "/assets/brands/WALTER.png" },
  { name: "weidmuller", image: "/assets/brands/weidmuller.png" },
  { name: "WEST_CONTROL", image: "/assets/brands/WEST_CONTROL.png" },
  { name: "WIELAND", image: "/assets/brands/WIELAND.png" },
  { name: "YASKAWA", image: "/assets/brands/YASKAWA.png" },
  { name: "Yokogawa", image: "/assets/brands/Yokogawa.png" },
  { name: "ZCC.CT", image: "/assets/brands/ZCC.CT.png" },
];

export default brands;
