import * as React from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import IconButton from "@mui/material/IconButton";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import GithubIcon from "@mui/icons-material/GitHub";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import InstagramIcon from "@mui/icons-material/Instagram";
import MailIcon from "@mui/icons-material/Mail";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import scanner_whatsapp from "../assets/scanner_whatsapp.jpeg";
import companyLogo from "../assets/companyLogo.png";
import { useLocation } from "react-router-dom";

const logoStyle = {
  width: "250px",
  height: "auto",
};

function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary" mt={1}>
      {"Copyright © "}
      <Link href="https://www.linkedin.com/in/ik159/">OneFive9 Dev&nbsp;</Link>
      {new Date().getFullYear()}
    </Typography>
  );
}

export default function Footer() {
  const location = useLocation();

  // Check if the current path is the homepage
  const isHomePage = location.pathname === "/";

  return (
    <Container
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: { xs: 4, sm: 8 },
        py: { xs: 1, sm: 2 },
        textAlign: { sm: "center", md: "left" },
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
          width: "100%",
          justifyContent: "space-between",
          alignItems: { xs: "center" },
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 4,
          }}
        >
          <Box
            sx={{
              width: { xs: "100%", sm: "60%" },
            }}
          >
            <Box>
              <img src={companyLogo} style={logoStyle} alt="logo of sitemark" />
            </Box>
            <Stack
              direction="row"
              spacing={1}
              useFlexGap
              sx={{
                color: "text.secondary",
                justifyContent: {
                  md: "normal",
                  lg: "normal",
                  sm: "center",
                  xs: "center",
                },
              }}
            >
              <IconButton
                color="inherit"
                href="https://instagram.com/smartglobalgt"
                aria-label="Insta"
                sx={{ alignSelf: "center" }}
              >
                <InstagramIcon />
              </IconButton>
              <IconButton
                color="inherit"
                target="_blank"
                href="mailto:smartsales@smartglobalgt.com"
                aria-label="Gmail"
                sx={{ alignSelf: "center" }}
              >
                <MailIcon />
              </IconButton>
              <IconButton
                color="inherit"
                target="_blank"
                onClick={() => {
                  window.open("https://wa.me/message/E6ISHYZGBBKAE1", "_blank");
                }}
                aria-label="Gamil"
                sx={{ alignSelf: "center" }}
              >
                <WhatsAppIcon />
              </IconButton>
            </Stack>
            {/* <Box>
              {" "}
              <img
                src={scanner_whatsapp}
                style={{ height: "150px" }}
                alt="logo of sitemark"
              />
            </Box> */}
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 4,
          }}
        >
          <Box>
            <img
              src={scanner_whatsapp}
              style={{ height: "170px" }}
              alt="logo of sitemark"
            />
          </Box>
        </Box>
        <Box
          sx={{
            display: { xs: "none", sm: "flex" },
            flexDirection: "column",
            gap: 1,
          }}
        >
          <Typography variant="body2" fontWeight={600}>
            We supply
          </Typography>
          <Link color="text.secondary" href="#">
            Electrical Component
          </Link>
          <Link color="text.secondary" href="#">
            Electrical Automation
          </Link>
          <Link color="text.secondary" href="#">
            Test Equipment
          </Link>
          <Link color="text.secondary" href="#">
            Machine Tools
          </Link>
          <Link color="text.secondary" href="#">
            Cutting Tools
          </Link>
        </Box>

        <Box
          sx={{
            display: { xs: "none", sm: "flex" },
            flexDirection: "column",
            gap: 1,
          }}
        >
          <Typography variant="body2" fontWeight={600}>
            Industries
          </Typography>
          <Link color="text.secondary" href="#">
            Aviation
          </Link>
          <Link color="text.secondary" href="#">
            Ports/Maritime/Marine
          </Link>
          <Link color="text.secondary" href="#">
            Training/Educational/Engineering Aids
          </Link>
          <Link color="text.secondary" href="#">
            Corporates
          </Link>
          <Link color="text.secondary" href="#">
            Manufacturing
          </Link>
        </Box>
        <Box
          sx={{
            display: { xs: "none", sm: "flex" },
            flexDirection: "column",
            gap: 1,
          }}
        >
          <Typography variant="body2" fontWeight={600}>
            Address
          </Typography>
          <Link color="text.secondary" href="#">
            Abu Dhabi, UAE
          </Link>
          <Link color="text.secondary" href="#">
            +971 28 767 275
          </Link>
          <Link color="text.secondary" href="#">
            +971 552 553 056
          </Link>
          <Link
            color="text.secondary"
            href="mailto:smartsales@smartglobalgt.com"
          >
            smartsales@smartglobalgt.com
          </Link>
          <Link color="text.secondary" href="mailto:smart.globalgt@gmail.com">
            smart.globalgt@gmail.com
          </Link>
        </Box>
      </Box>
      {isHomePage && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            pt: { xs: 1, sm: 2 },
            width: "100%",
            borderTop: "1px solid",
            borderColor: "divider",
          }}
        >
          {" "}
          <Box sx={{ textAlign: "center" }}>
            {/* <Link color="text.secondary" href="#">
            Privacy Policy
          </Link>
          <Typography display="inline" sx={{ mx: 0.5, opacity: 0.5 }}>
            &nbsp;•&nbsp;
          </Typography>
          <Link color="text.secondary" href="#">
            Terms of Service
          </Link> */}
            <Copyright />
          </Box>
          <Stack
            direction="row"
            sx={{
              color: "text.secondary",
            }}
          >
            <IconButton
              color="inherit"
              target="_blank"
              href="mailto:itsik159@gmail.com"
              aria-label="Gamil"
            >
              <MailIcon />
            </IconButton>
            <IconButton
              color="inherit"
              target="_blank"
              href="https://github.com/ik159"
              aria-label="GitHub"
            >
              <GithubIcon />
            </IconButton>
            <IconButton
              color="inherit"
              target="_blank"
              href="https://instagram.com/ik.159"
              aria-label="X"
            >
              <InstagramIcon />
            </IconButton>
            <IconButton
              color="inherit"
              target="_blank"
              href="https://www.linkedin.com/in/ik159"
              aria-label="LinkedIn"
            >
              <LinkedInIcon />
            </IconButton>
          </Stack>{" "}
        </Box>
      )}
    </Container>
  );
}
