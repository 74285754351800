import * as React from "react";

import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import { ThemeProvider, createTheme } from "@mui/material/styles";
// import SvgMaterialDesign from '../icons/SvgMaterialDesign';
import Hero from "../components/Hero";
import Highlights from "../components/Highlights";
import FAQ from "../components/FAQ";
// import Footer from "../components/Footer";
import getLPTheme from "../styles/getLPTheme";
import GetInTouch from "../components/GetInTouch";
import BrandCarousel from "../components/BrandCarousel";
import ProductCategories from "../components/ProductCategories";

const LandingPage = () => {
  const LPtheme = createTheme(getLPTheme("light"));

  return (
    <ThemeProvider theme={LPtheme}>
      <CssBaseline />
      {/* <AppBarSticky mode={mode} toggleColorMode={toggleColorMode} /> */}

      {/* <AppAppBar mode={mode} toggleColorMode={toggleColorMode} /> */}
      <Hero />
      <Divider />
      {/* <LandingPageAppBar /> */}
      {/* <ProductCarousel />
      <Divider /> */}
      {/* <CarouselComponent /> */}
      <ProductCategories />
      <Divider />
      <BrandCarousel />
      <Divider />
      <Highlights />

      {/* <Divider /> */}
      {/* <ProductCarousel /> */}
      {/* <Divider /> */}
      {/* <Testimonials /> */}
      <Divider />
      <GetInTouch />
      <Divider />
      <FAQ />
    </ThemeProvider>
  );
};
export default LandingPage;
