export default function StatsCard({ toggle, settoggle }) {
  return (
    <div class="bg-red-50 pt-12 sm:pt-16">
      <div class="mt-2 bg-white  pb-12 sm:pb-16">
        <div class="relative">
          <div class="absolute inset-0 h-1/2 bg-red-50 "></div>
          <div class="relative mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
            <div class="mx-auto max-w-4xl">
              <dl class="rounded-lg bg-white  shadow-lg sm:grid sm:grid-cols-3">
                <div class="flex flex-col border-b border-gray-100  p-6 text-center sm:border-0 sm:border-r">
                  <dt class="order-2 mt-2 text-lg font-normal leading-6 text-gray-500">
                    Happy Clients
                  </dt>
                  <dd class="order-1 text-5xl  tracking-tight text-red-700 ">
                    <span>110</span>+
                  </dd>
                </div>
                <div class="flex flex-col border-t border-b border-gray-100  p-6 text-center sm:border-0 sm:border-l sm:border-r">
                  <dt class="order-2 mt-2 text-lg font-normal leading-6 text-gray-500 ">
                    Products
                  </dt>
                  <dd class="order-1 text-5xl tracking-tight text-red-700 ">
                    <span>1350</span>+
                  </dd>
                </div>
                <div class="flex flex-col border-t border-gray-100  p-6 text-center sm:border-0 sm:border-l">
                  <dt class="order-2 mt-2 text-lg font-normal leading-6 text-gray-500 ">
                    Years In Service
                  </dt>
                  <dd class="order-1 text-5xl  tracking-tight text-red-700 ">
                    <span>30</span>+
                  </dd>
                </div>
              </dl>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
