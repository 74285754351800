// data.js

const productscategories = [
  {
    name: "Cables",
    image: "/assets/productCategories/cables.jpg",
    description: "",
  },
  {
    name: "Cold Storage Parts",
    image: "/assets/productCategories/cold-storage.jpg",
    description:
      "We specialize in supplying genuine parts directly from the USA and Europe, ensuring the highest quality and reliability for your refrigeration systems. Our extensive inventory includes essential components for cold storage and industrial applications.",
  },
  {
    name: "Computer & IT Products",
    image: "/assets/productCategories/ITproducts.png",
    description:
      "We provide cutting-edge Computer and IT products tailored to meet your technology needs, ensuring top performance and reliability.",
  },
  {
    name: "Electrical & Electronics",
    image: "/assets/productCategories/electrical-parts.jpg",
    description: "",
  },

  // {
  //   name: "Electrical & Electronics",
  //   image: "/assets/productCategories/Electrical & Electronics.avif",
  //   description: "",
  // },

  {
    name: "General Trading",
    image: "/assets/productCategories/general-trading.jpeg",
    description: "",
  },
  {
    name: "HVAC & Air Conditioning",
    image: "/assets/productCategories/hvac.jpg",
    description: "",
  },
  {
    name: "Import-Export",
    image: "/assets/productCategories/import.jpg",
    description:
      "We offer a comprehensive selection of spare parts, sourced from all leading manufacturers to ensure compatibility and performance",
  },
  {
    name: "Marine Products",
    image: "/assets/productCategories/marine.webp",
    description: "",
  },
  {
    name: "TVH-Total Source Parts",
    image: "/assets/productCategories/tvh.png",
    description: "",
  },
  {
    name: "Workshop Equipment",
    image: "/assets/productCategories/workshop_equipment.jpg",
    description:
      "We are specialist in providing leading Forklift spare parts solutions to our customers in UAE. We supply both brand new and used Forklift Spare Parts for almost all the makers of Forklifts. We supply spare parts for Forklift of all capacities. We have a huge inventory of Forklift Spare Parts available in stock to cater to our customers who are looking for cost effective and timely supply of materials.",
  },
];

const products = [
  {
    name: "Coaxial Items",
    category: "General Trading",
    brand: "",
    description: "",
    image: "/assets/general/Coaxial_Items_1.jpg",
    id: 1001,
  },
  {
    name: "Networking Items",
    category: "General Trading",
    brand: "",
    description: "",
    image: "/assets/general/Networking_Items_1.jpg",
    id: 1002,
  },
  {
    name: "Power Supply & Adaptors",
    category: "General Trading",
    brand: "",
    description: "",
    image: "/assets/general/Power_Supply_&_Adaptors_1.jpg",
    id: 1003,
  },
  {
    name: "Cooling Spare Parts",
    category: "General Trading",
    brand: "",
    description: "",
    image: "/assets/general/Cooling_Spare_Parts_1.jpg",
    id: 1004,
  },
  {
    name: "Rubber Spare Parts",
    category: "General Trading",
    brand: "",
    description: "",
    image: "/assets/general/Rubber_Spare_Parts_1.jpg",
    id: 1005,
  },
  {
    name: "Hydraulic Spare Parts",
    category: "General Trading",
    brand: "",
    description: "",
    image: "/assets/general/Hydraulic_Spare_Parts_1.jpg",
    id: 1006,
  },
  {
    name: "Electrical Spare Parts",
    category: "General Trading",
    brand: "",
    description: "",
    image: "/assets/general/Electrical_Spare_Parts_1.jpg",
    id: 1007,
  },
  {
    name: "Chassis Spare Parts",
    category: "General Trading",
    brand: "",
    description: "",
    image: "/assets/general/Chassis_Spare_Parts_1.jpg",
    id: 1008,
  },
  {
    name: "Transmission Spare Parts",
    category: "General Trading",
    brand: "",
    description: "",
    image: "/assets/general/Transmission_Spare_Parts_1.jpg",
    id: 1009,
  },
  {
    name: "Engine Spare Parts",
    category: "General Trading",
    brand: "",
    description: "",
    image: "/assets/general/Engine_Spare_Parts_1.jpg",
    id: 1010,
  },
  {
    name: "Kalmar",
    category: "Workshop Equipment",
    brand: "",
    description: "",
    image: "/assets/workshopEquipment/kalmar.png",
    id: 1011,
  },
  {
    name: "Caterpillar",
    category: "Workshop Equipment",
    brand: "",
    description: "",
    image: "/assets/workshopEquipment/cat.jpg",
    id: 1012,
  },
  {
    name: "Doosan",
    category: "Workshop Equipment",
    brand: "",
    description: "",
    image: "/assets/workshopEquipment/doosan.png",
    id: 1013,
  },
  {
    name: "Cascade",
    category: "Workshop Equipment",
    brand: "",
    description: "",
    image: "/assets/workshopEquipment/cascade.jpg",
    id: 1014,
  },
  {
    name: "Hyster",
    category: "Workshop Equipment",
    brand: "",
    description: "",
    image: "/assets/workshopEquipment/hyster.png",
    id: 1015,
  },
  {
    name: "Genie",
    category: "Workshop Equipment",
    brand: "",
    description: "",
    image: "/assets/workshopEquipment/genie.jpg",
    id: 1016,
  },
  {
    name: "Clark",
    category: "Workshop Equipment",
    brand: "",
    description: "",
    image: "/assets/workshopEquipment/clark.png",
    id: 1017,
  },
  {
    name: "Komatsu",
    category: "Workshop Equipment",
    brand: "",
    description: "",
    image: "/assets/workshopEquipment/komatsu.png",
    id: 1018,
  },
  {
    name: "Feeler",
    category: "Workshop Equipment",
    brand: "",
    description: "",
    image: "/assets/workshopEquipment/feeler.jpeg",
    id: 1019,
  },
  {
    name: "BALLAST",
    category: "Electrical & Electronics",
    brand: "",
    description: "",
    image: "/assets/electricalAccessories/BALLAST_4.jpg",
    id: 1020,
  },
  {
    name: "BREAKER",
    category: "Electrical & Electronics",
    brand: "",
    description: "",
    image: "/assets/electricalAccessories/BREAKER_2.jpg",
    id: 1021,
  },
  {
    name: "BUZZER",
    category: "Electrical & Electronics",
    brand: "",
    description: "",
    image: "/assets/electricalAccessories/BUZZER_1.jpg",
    id: 1022,
  },
  {
    name: "CABLE CLIP",
    category: "Electrical & Electronics",
    brand: "",
    description: "",
    image: "/assets/electricalAccessories/CABLE_CLIP_3.jpg",
    id: 1023,
  },
  {
    name: "CABLE GLAND",
    category: "Electrical & Electronics",
    brand: "",
    description: "",
    image: "/assets/electricalAccessories/CABLE_GLAND_5.jpg",
    id: 1024,
  },
  {
    name: "CABLE LUGS",
    category: "Electrical & Electronics",
    brand: "",
    description: "",
    image: "/assets/electricalAccessories/CABLE_LUGS_2.jpg",
    id: 1025,
  },
  {
    name: "CABLE MARKER",
    category: "Electrical & Electronics",
    brand: "",
    description: "",
    image: "/assets/electricalAccessories/CABLE_MARKER_3.jpg",
    id: 1026,
  },
  {
    name: "CABLE REEL",
    category: "Electrical & Electronics",
    brand: "",
    description: "",
    image: "/assets/electricalAccessories/CABLE_REEL_5.jpg",
    id: 1027,
  },
  {
    name: "Cable Tie",
    category: "Electrical & Electronics",
    brand: "",
    description: "",
    image: "/assets/electricalAccessories/Cable_Tie_4.jpg",
    id: 1028,
  },
  {
    name: "CELING ROSE",
    category: "Electrical & Electronics",
    brand: "",
    description: "",
    image: "/assets/electricalAccessories/CELING_ROSE_4.jpg",
    id: 1029,
  },
  {
    name: "CLAMP",
    category: "Electrical & Electronics",
    brand: "",
    description: "",
    image: "/assets/electricalAccessories/CLAMP_4.jpg",
    id: 1030,
  },
  {
    name: "GI & PVC CONDUIT ",
    category: "Electrical & Electronics",
    brand: "",
    description: "",
    image: "/assets/electricalAccessories/GI_&_PVC_CONDUIT_5.jpg",
    id: 1031,
  },
  {
    name: "Switch",
    category: "Electrical & Electronics",
    brand: "",
    description: "",
    image: "/assets/electricalAccessories/Switch_4.jpg",
    id: 1032,
  },
  {
    name: "Socket",
    category: "Electrical & Electronics",
    brand: "",
    description: "",
    image: "/assets/electricalAccessories/Socket_2.jpg",
    id: 1033,
  },
  {
    name: "Extension",
    category: "Electrical & Electronics",
    brand: "",
    description: "",
    image: "/assets/electricalAccessories/Extension_3.jpg",
    id: 1034,
  },
  {
    name: "Relay",
    category: "Electrical & Electronics",
    brand: "",
    description: "",
    image: "/assets/electricalAccessories/Relay_5.jpg",
    id: 1035,
  },
  {
    name: "Relay Base",
    category: "Electrical & Electronics",
    brand: "",
    description: "",
    image: "/assets/electricalAccessories/Relay_Base_1.jpg",
    id: 1036,
  },
  {
    name: "GI & PVC SADDEL",
    category: "Electrical & Electronics",
    brand: "",
    description: "",
    image: "/assets/electricalAccessories/GI_&_PVC_SADDEL_5.jpg",
    id: 1037,
  },
  {
    name: "ELECTRICAL PVC PIPE",
    category: "Electrical & Electronics",
    brand: "",
    description: "",
    image: "/assets/electricalAccessories/ELECTRICAL_PVC_PIPE_5.jpg",
    id: 1038,
  },
  {
    name: "Plug",
    category: "Electrical & Electronics",
    brand: "",
    description: "",
    image: "/assets/electricalAccessories/Plug_3.jpg",
    id: 1039,
  },
  {
    name: "PVC & GI Trucking",
    category: "Electrical & Electronics",
    brand: "",
    description: "",
    image: "/assets/electricalAccessories/PVC_&_GI_Trucking_5.jpg",
    id: 1040,
  },
  {
    name: "Electrical Tape",
    category: "Electrical & Electronics",
    brand: "",
    description: "",
    image: "/assets/electricalAccessories/Electrical_Tape_3.jpg",
    id: 1041,
  },
  {
    name: "Fuse",
    category: "Electrical & Electronics",
    brand: "",
    description: "",
    image: "/assets/electricalAccessories/Fuse_5.jpg",
    id: 1042,
  },
  {
    name: "Holder",
    category: "Electrical & Electronics",
    brand: "",
    description: "",
    image: "/assets/electricalAccessories/Holder_4.jpg",
    id: 1043,
  },
  {
    name: "Bulbs",
    category: "Electrical & Electronics",
    brand: "",
    description: "",
    image: "/assets/electronics/Bulbs_1.jpg",
    id: 1044,
  },
  {
    name: "Tube Rods ",
    category: "Electrical & Electronics",
    brand: "",
    description: "",
    image: "/assets/electronics/Tube_Rods_2.jpg",
    id: 1045,
  },
  {
    name: "Energy Saving CFL Lamp",
    category: "Electrical & Electronics",
    brand: "",
    description: "",
    image: "/assets/electronics/Energy_Saving_CFL_Lamp_2.jpg",
    id: 1046,
  },
  {
    name: "Halogen Lamp ",
    category: "Electrical & Electronics",
    brand: "",
    description: "",
    image: "/assets/electronics/Halogen_Lamp_4.jpg",
    id: 1047,
  },
  {
    name: "Metal Halide Lamp ",
    category: "Electrical & Electronics",
    brand: "",
    description: "",
    image: "/assets/electronics/Metal_Halide_Lamp_2.jpg",
    id: 1048,
  },
  {
    name: "Cup Lamp ",
    category: "Electrical & Electronics",
    brand: "",
    description: "",
    image: "/assets/electronics/Cup_Lamp.jpg",
    id: 1049,
  },
  {
    name: "Capsule Lamp ",
    category: "Electrical & Electronics",
    brand: "",
    description: "",
    image: "/assets/electronics/Capsule_Lamp_1.jpg",
    id: 1050,
  },
  {
    name: "Emergency Tube ",
    category: "Electrical & Electronics",
    brand: "",
    description: "",
    image: "/assets/electronics/Emergency_Tube_5.jpg",
    id: 1051,
  },
  {
    name: "Flower Light",
    category: "Electrical & Electronics",
    brand: "",
    description: "",
    image: "/assets/electronics/Flower_Light_5.jpg",
    id: 1052,
  },
  {
    name: "PL Lamp",
    category: "Electrical & Electronics",
    brand: "",
    description: "",
    image: "/assets/electronics/PL_Lamp_2.jpg",
    id: 1053,
  },
  {
    name: "Mercury Light ",
    category: "Electrical & Electronics",
    brand: "",
    description: "",
    image: "/assets/electronics/Mercury_Light_3.jpg",
    id: 1054,
  },
  {
    name: "Mirror Light ",
    category: "Electrical & Electronics",
    brand: "",
    description: "",
    image: "/assets/electronics/Mirror_Light_3.jpg",
    id: 1055,
  },
  {
    name: "Garden Light ",
    category: "Electrical & Electronics",
    brand: "",
    description: "",
    image: "/assets/electronics/Garden_Light_1.jpg",
    id: 1056,
  },
  {
    name: "LED Lamps ",
    category: "Electrical & Electronics",
    brand: "",
    description: "",
    image: "/assets/electronics/LED_Lamps_4.jpg",
    id: 1057,
  },
  {
    name: "Insect Killer Tube ",
    category: "Electrical & Electronics",
    brand: "",
    description: "",
    image: "/assets/electronics/Insect_Killer_Tube_3.jpg",
    id: 1058,
  },
  {
    name: "PAR 38 Lamps ",
    category: "Electrical & Electronics",
    brand: "",
    description: "",
    image: "/assets/electronics/PAR_38_Lamps_3.jpg",
    id: 1059,
  },
  {
    name: "Down Light",
    category: "Electrical & Electronics",
    brand: "",
    description: "",
    image: "/assets/electronics/Down_Light_3.jpg",
    id: 1060,
  },
  {
    name: "Spot Lamps ",
    category: "Electrical & Electronics",
    brand: "",
    description: "",
    image: "/assets/electronics/Spot_Lamps_5.jpg",
    id: 1061,
  },
  {
    name: "Hanging Lamps ",
    category: "Electrical & Electronics",
    brand: "",
    description: "",
    image: "/assets/electronics/Hanging_Lamps_4.jpg",
    id: 1062,
  },
  {
    name: "LED Strips",
    category: "Electrical & Electronics",
    brand: "",
    description: "",
    image: "/assets/electronics/LED_Strips_3.jpg",
    id: 1063,
  },
  {
    name: "Parker ",
    category: "Cold Storage Parts",
    brand: "",
    description: "",
    image: "/assets/coldStorage/Parker__logo_3.jpg",
    id: 1064,
  },
  {
    name: "Philips ",
    category: "Cold Storage Parts",
    brand: "",
    description: "",
    image: "/assets/coldStorage/Philips__logo_5.jpg",
    id: 1065,
  },
  {
    name: "Cyrus Shank",
    category: "Cold Storage Parts",
    brand: "",
    description: "",
    image: "/assets/coldStorage/Cyrus_Shank_logo_2.jpg",
    id: 1066,
  },
  {
    name: "Danfoss ",
    category: "Cold Storage Parts",
    brand: "",
    description: "",
    image: "/assets/coldStorage/Danfoss__logo_5.jpg",
    id: 1067,
  },
  {
    name: "Vilter ",
    category: "Cold Storage Parts",
    brand: "",
    description: "",
    image: "/assets/coldStorage/Vilter__logo_1.jpg",
    id: 1068,
  },
  {
    name: "Frick ",
    category: "Cold Storage Parts",
    brand: "",
    description: "",
    image: "/assets/coldStorage/Frick__logo_2.jpg",
    id: 1069,
  },
  {
    name: "Castel",
    category: "Cold Storage Parts",
    brand: "",
    description: "",
    image: "/assets/coldStorage/Castel_logo_2.jpg",
    id: 1070,
  },
  {
    name: "Sub-Zero",
    category: "Cold Storage Parts",
    brand: "",
    description: "",
    image: "/assets/coldStorage/Sub-Zero_logo_1.jpg",
    id: 1071,
  },
  {
    name: "Maneurop",
    category: "Cold Storage Parts",
    brand: "",
    description: "",
    image: "/assets/coldStorage/Maneurop_logo_1.jpg",
    id: 1072,
  },
  {
    name: "Arthermo",
    category: "Cold Storage Parts",
    brand: "",
    description: "",
    image: "/assets/coldStorage/Arthermo_logo_1.jpg",
    id: 1073,
  },
  {
    name: "Errecom",
    category: "Cold Storage Parts",
    brand: "",
    description: "",
    image: "/assets/coldStorage/Errecom_logo_2.jpg",
    id: 1074,
  },
  {
    name: "AKO ",
    category: "Cold Storage Parts",
    brand: "",
    description: "",
    image: "/assets/coldStorage/AKO__logo_1.jpg",
    id: 1075,
  },
  {
    name: "Theodoor",
    category: "Cold Storage Parts",
    brand: "",
    description: "",
    image: "/assets/coldStorage/Theodoor_logo_1.jpg",
    id: 1076,
  },
  {
    name: "Komans",
    category: "Cold Storage Parts",
    brand: "",
    description: "",
    image: "/assets/coldStorage/Komans_logo_1.jpg",
    id: 1077,
  },
  {
    name: "Eyyani",
    category: "Cold Storage Parts",
    brand: "",
    description: "",
    image: "/assets/coldStorage/Eyyani_logo_2.jpg",
    id: 1078,
  },
  {
    name: "Axial",
    category: "Cold Storage Parts",
    brand: "",
    description: "",
    image: "/assets/coldStorage/Axial_logo_1.jpg",
    id: 1079,
  },
  {
    name: "Wellmate",
    category: "Cold Storage Parts",
    brand: "",
    description: "",
    image: "/assets/coldStorage/Wellmate_logo_3.jpg",
    id: 1080,
  },
  {
    name: "Friga-Bohn",
    category: "Cold Storage Parts",
    brand: "",
    description: "",
    image: "/assets/coldStorage/Friga-Bohn_logo_2.jpg",
    id: 1081,
  },
  {
    name: "Lennox",
    category: "Cold Storage Parts",
    brand: "",
    description: "",
    image: "/assets/coldStorage/Lennox_logo_4.jpg",
    id: 1082,
  },
  {
    name: "Mueller",
    category: "Cold Storage Parts",
    brand: "",
    description: "",
    image: "/assets/coldStorage/Mueller_logo_4.jpg",
    id: 1083,
  },
  {
    name: "Embaco ",
    category: "Cold Storage Parts",
    brand: "",
    description: "",
    image: "/assets/coldStorage/Embaco__logo_4.jpg",
    id: 1084,
  },
  {
    name: "Kranzle",
    category: "Cold Storage Parts",
    brand: "",
    description: "",
    image: "/assets/coldStorage/Kranzle_logo_3.jpg",
    id: 1085,
  },
  {
    name: "Riva Cold",
    category: "Cold Storage Parts",
    brand: "",
    description: "",
    image: "/assets/coldStorage/Riva_Cold_logo_3.jpg",
    id: 1086,
  },
  {
    name: "Marine Deck & Hull",
    category: "Marine Products",
    brand: "",
    description: "",
    image: "/assets/marine/Marine_Deck_&_Hull_1.jpg",
    id: 1087,
  },
  {
    name: "Marine Engine Room",
    category: "Marine Products",
    brand: "",
    description: "",
    image: "/assets/marine/Marine_Engine_Room_1.jpg",
    id: 1088,
  },
  {
    name: "Marine Propulsion",
    category: "Marine Products",
    brand: "",
    description: "",
    image: "/assets/marine/Marine_Propulsion_1.jpg",
    id: 1089,
  },
  {
    name: "Marine Pumps, Plumbing, & Sanitary",
    category: "Marine Products",
    brand: "",
    description: "",
    image: "/assets/marine/Marine_Pumps,_Plumbing,_&_Sanitary_1.jpg",
    id: 1090,
  },
  {
    name: "Marine Ventilation, Hatches & Air Conditioners",
    category: "Marine Products",
    brand: "",
    description: "",
    image:
      "/assets/marine/Marine_Ventilation,_Hatches_&_Air_Conditioners_1.jpg",
    id: 1091,
  },
  {
    name: "Marine Maneuvering & Stabilizing",
    category: "Marine Products",
    brand: "",
    description: "",
    image: "/assets/marine/Marine_Maneuvering_&_Stabilizing_1.jpg",
    id: 1092,
  },
  {
    name: "Marine Seats & Pedestals",
    category: "Marine Products",
    brand: "",
    description: "",
    image: "/assets/marine/Marine_Seats_&_Pedestals_1.jpg",
    id: 1093,
  },
  {
    name: "Marine Electrical",
    category: "Marine Products",
    brand: "",
    description: "",
    image: "/assets/marine/Marine_Electrical_1.jpg",
    id: 1094,
  },
  {
    name: "Marine Lighting",
    category: "Marine Products",
    brand: "",
    description: "",
    image: "/assets/marine/Marine_Lighting_1.jpg",
    id: 1095,
  },
  {
    name: "Marine Monitoring & Controls",
    category: "Marine Products",
    brand: "",
    description: "",
    image: "/assets/marine/Marine_Monitoring_&_Controls_1.jpg",
    id: 1096,
  },
  {
    name: "Marine Hardware & Accessories",
    category: "Marine Products",
    brand: "",
    description: "",
    image: "/assets/marine/Marine_Hardware_&_Accessories_1.jpg",
    id: 1097,
  },
  {
    name: "Marine Galley Equipment",
    category: "Marine Products",
    brand: "",
    description: "",
    image: "/assets/marine/Marine_Galley_Equipment_1.jpg",
    id: 1098,
  },
  {
    name: "Marine Boat Care, Adhesives, & Protection",
    category: "Marine Products",
    brand: "",
    description: "",
    image: "/assets/marine/Marine_Boat_Care,_Adhesives,_&_Protection_1.jpg",
    id: 1099,
  },
  {
    name: "Marine Marina Accessories",
    category: "Marine Products",
    brand: "",
    description: "",
    image: "/assets/marine/Marine_Marina_Accessories_1.jpg",
    id: 1100,
  },
  {
    name: "Marine Decking & Construction Material",
    category: "Marine Products",
    brand: "",
    description: "",
    image: "/assets/marine/Marine_Decking_&_Construction_Material_1.jpg",
    id: 1101,
  },
  {
    name: "Marine Steering kits",
    category: "Marine Products",
    brand: "",
    description: "",
    image: "/assets/marine/Marine_Steering_kits_1.jpg",
    id: 1102,
  },
  {
    name: "Marine Steering Cylinders",
    category: "Marine Products",
    brand: "",
    description: "",
    image: "/assets/marine/Marine_Steering_Cylinders_1.jpg",
    id: 1103,
  },
  {
    name: "Marine Steering Pumps",
    category: "Marine Products",
    brand: "",
    description: "",
    image: "/assets/marine/Marine_Steering_Pumps_1.jpg",
    id: 1104,
  },
  {
    name: "Marine Power Packs",
    category: "Marine Products",
    brand: "",
    description: "",
    image: "/assets/marine/Marine_Power_Packs_1.jpg",
    id: 1105,
  },
  {
    name: "Marine Steering Accessories",
    category: "Marine Products",
    brand: "",
    description: "",
    image: "/assets/marine/Marine_Steering_Accessories_1.jpg",
    id: 1106,
  },
  {
    name: "Marine Steering Wheels",
    category: "Marine Products",
    brand: "",
    description: "",
    image: "/assets/marine/Marine_Steering_Wheels_1.jpg",
    id: 1107,
  },
  {
    name: "Marine Rudders",
    category: "Marine Products",
    brand: "",
    description: "",
    image: "/assets/marine/Marine_Rudders_1.jpg",
    id: 1108,
  },
  {
    name: "Marine Bow Thrusters",
    category: "Marine Products",
    brand: "",
    description: "",
    image: "/assets/marine/Marine_Bow_Thrusters_1.jpg",
    id: 1109,
  },
  {
    name: "Marine Stern Thrusters",
    category: "Marine Products",
    brand: "",
    description: "",
    image: "/assets/marine/Marine_Stern_Thrusters_1.jpg",
    id: 1110,
  },
  {
    name: "Marine Tunnels",
    category: "Marine Products",
    brand: "",
    description: "",
    image: "/assets/marine/Marine_Tunnels_1.jpg",
    id: 1111,
  },
  {
    name: "Marine Controls & Marine Auto Pilot",
    category: "Marine Products",
    brand: "",
    description: "",
    image: "/assets/marine/Marine_Controls_&_Marine_Auto_Pilot_1.jpg",
    id: 1112,
  },
  {
    name: "Marine Trim Tabs",
    category: "Marine Products",
    brand: "",
    description: "",
    image: "/assets/marine/Marine_Trim_Tabs_1.jpg",
    id: 1113,
  },
  {
    name: "Marine Stabilizers",
    category: "Marine Products",
    brand: "",
    description: "",
    image: "/assets/marine/Marine_Stabilizers_1.jpg",
    id: 1114,
  },
  {
    name: "Marine Seats & Pedestals",
    category: "Marine Products",
    brand: "",
    description: "",
    image: "/assets/marine/Marine_Seats_&_Pedestals_1.jpg",
    id: 1115,
  },
  {
    name: "Marine Electrical",
    category: "Marine Products",
    brand: "",
    description: "",
    image: "/assets/marine/Marine_Electrical_1.jpg",
    id: 1116,
  },
  {
    name: "Marine Lighting",
    category: "Marine Products",
    brand: "",
    description: "",
    image: "/assets/marine/Marine_Lighting_1.jpg",
    id: 1117,
  },
  {
    name: "Marine Monitoring & Controls",
    category: "Marine Products",
    brand: "",
    description: "",
    image: "/assets/marine/Marine_Monitoring_&_Controls_1.jpg",
    id: 1118,
  },
  {
    name: "Marine Hardware & Accessories",
    category: "Marine Products",
    brand: "",
    description: "",
    image: "/assets/marine/Marine_Hardware_&_Accessories_1.jpg",
    id: 1119,
  },
  {
    name: "Marine Galley Equipment",
    category: "Marine Products",
    brand: "",
    description: "",
    image: "/assets/marine/Marine_Galley_Equipment_1.jpg",
    id: 1120,
  },
  {
    name: "Marine Boat Care, Adhesives, & Protection",
    category: "Marine Products",
    brand: "",
    description: "",
    image: "/assets/marine/Marine_Boat_Care,_Adhesives,_&_Protection_1.jpg",
    id: 1121,
  },
  {
    name: "Marine Marina Accessories",
    category: "Marine Products",
    brand: "",
    description: "",
    image: "/assets/marine/Marine_Marina_Accessories_1.jpg",
    id: 1122,
  },
  {
    name: "Marine Decking & Construction Material",
    category: "Marine Products",
    brand: "",
    description: "",
    image: "/assets/marine/Marine_Decking_&_Construction_Material_1.jpg",
    id: 1123,
  },
  {
    name: "Marine Safety Gears",
    category: "Marine Products",
    brand: "",
    description: "",
    image: "/assets/marine/Marine_Safety_Gears_1.jpg",
    id: 1124,
  },
  {
    name: "Telephone Cable",
    category: "Cables",
    brand: "",
    description: "",
    image: "/assets/cables/Telephone_Cable_1.jpg",
    id: 1125,
  },

  {
    name: "Data Cable",
    category: "Cables",
    brand: "",
    description: "",
    image: "/assets/cables/Data_Cable_1.jpg",
    id: 1126,
  },

  {
    name: "Control Cable",
    category: "Cables",
    brand: "",
    description: "",
    image: "/assets/cables/Control_Cable_1.jpg",
    id: 1127,
  },

  {
    name: "Flexible Cable",
    category: "Cables",
    brand: "",
    description: "",
    image: "/assets/cables/Flexible_Cable_1.jpg",
    id: 1128,
  },

  {
    name: "Panel Cable",
    category: "Cables",
    brand: "",
    description: "",
    image: "/assets/cables/Panel_Cable_1.jpg",
    id: 1129,
  },

  {
    name: "Rubber Cable",
    category: "Cables",
    brand: "",
    description: "",
    image: "/assets/cables/Rubber_Cable_1.jpg",
    id: 1130,
  },

  {
    name: "Earth Cable",
    category: "Cables",
    brand: "",
    description: "",
    image: "/assets/cables/Earth_Cable_1.jpg",
    id: 1131,
  },

  {
    name: "Jumper Cable",
    category: "Cables",
    brand: "",
    description: "",
    image: "/assets/cables/Jumper_Cable_1.jpg",
    id: 1132,
  },

  {
    name: "Fiber Optic Cable",
    category: "Cables",
    brand: "",
    description: "",
    image: "/assets/cables/Fiber_Optic_Cable_1.jpg",
    id: 1133,
  },

  {
    name: "Armored Cable",
    category: "Cables",
    brand: "",
    description: "",
    image: "/assets/cables/Armored_Cable_1.jpg",
    id: 1134,
  },

  {
    name: "Network Cable",
    category: "Cables",
    brand: "",
    description: "",
    image: "/assets/cables/Network_Cable_1.jpg",
    id: 1135,
  },

  {
    name: "Coaxial Cable",
    category: "Cables",
    brand: "",
    description: "",
    image: "/assets/cables/Coaxial_Cable_1.jpg",
    id: 1136,
  },

  {
    name: "VGA Cable Banana Cable",
    category: "Cables",
    brand: "",
    description: "",
    image: "/assets/cables/VGA_Cable_Banana_Cable_1.jpg",
    id: 1137,
  },

  {
    name: "HDMI Cable",
    category: "Cables",
    brand: "",
    description: "",
    image: "/assets/cables/HDMI_Cable_1.jpg",
    id: 1138,
  },

  {
    name: "Camera Cable",
    category: "Cables",
    brand: "",
    description: "",
    image: "/assets/cables/Camera_Cable_1.jpg",
    id: 1139,
  },

  {
    name: "Computer Cable",
    category: "Cables",
    brand: "",
    description: "",
    image: "/assets/cables/Computer_Cable_1.jpg",
    id: 1140,
  },

  {
    name: "Fire Alarm Cable",
    category: "Cables",
    brand: "",
    description: "",
    image: "/assets/cables/Fire_Alarm_Cable_1.jpg",
    id: 1141,
  },

  {
    name: "Speaker Cable",
    category: "Cables",
    brand: "",
    description: "",
    image: "/assets/cables/Speaker_Cable_1.jpg",
    id: 1142,
  },

  {
    name: "RCA Cable",
    category: "Cables",
    brand: "",
    description: "",
    image: "/assets/cables/RCA_Cable_1.jpg",
    id: 1143,
  },

  {
    name: "Instrument Cable",
    category: "Cables",
    brand: "",
    description: "",
    image: "/assets/cables/Instrument_Cable_1.jpg",
    id: 1144,
  },
  {
    name: "Unitrac",
    category: "Import-Export",
    brand: "",
    description: "",
    image: "/assets/imports/Unitrac_logo_1.jpg",
    id: 1145,
  },
  {
    name: "Upright",
    category: "Import-Export",
    brand: "",
    description: "",
    image: "/assets/imports/Upright_logo_1.jpg",
    id: 1146,
  },
  {
    name: "Valmet",
    category: "Import-Export",
    brand: "",
    description: "",
    image: "/assets/imports/Valmet_logo_1.jpg",
    id: 1147,
  },
  {
    name: "Wagner",
    category: "Import-Export",
    brand: "",
    description: "",
    image: "/assets/imports/Wagner_logo_1.jpg",
    id: 1148,
  },
  {
    name: "Whiptruck",
    category: "Import-Export",
    brand: "",
    description: "",
    image: "/assets/imports/Whiptruck_logo_1.jpg",
    id: 1149,
  },
  {
    name: "Yale",
    category: "Import-Export",
    brand: "",
    description: "",
    image: "/assets/imports/Yale_logo_1.jpg",
    id: 1150,
  },
  {
    name: "Abeko",
    category: "Import-Export",
    brand: "",
    description: "",
    image: "/assets/imports/Abeko_logo_1.jpg",
    id: 1151,
  },
  {
    name: "Aichi",
    category: "Import-Export",
    brand: "",
    description: "",
    image: "/assets/imports/Aichi_logo_1.jpg",
    id: 1152,
  },
  {
    name: "AllisChalmers",
    category: "Import-Export",
    brand: "",
    description: "",
    image: "/assets/imports/AllisChalmers_logo_1.jpg",
    id: 1153,
  },
  {
    name: "Armanni",
    category: "Import-Export",
    brand: "",
    description: "",
    image: "/assets/imports/Armanni_logo_1.jpg",
    id: 1154,
  },
  {
    name: "Artison",
    category: "Import-Export",
    brand: "",
    description: "",
    image: "/assets/imports/Artison_logo_1.jpg",
    id: 1155,
  },
  {
    name: "Asea",
    category: "Import-Export",
    brand: "",
    description: "",
    image: "/assets/imports/Asea_logo_1.jpg",
    id: 1156,
  },
  {
    name: "Atlet",
    category: "Import-Export",
    brand: "",
    description: "",
    image: "/assets/imports/Atlet_logo_1.jpg",
    id: 1157,
  },
  {
    name: "Audureau",
    category: "Import-Export",
    brand: "",
    description: "",
    image: "/assets/imports/Audureau_logo_1.jpg",
    id: 1158,
  },
  {
    name: "Baka",
    category: "Import-Export",
    brand: "",
    description: "",
    image: "/assets/imports/Baka_logo_1.jpg",
    id: 1159,
  },
  {
    name: "Balkancar",
    category: "Import-Export",
    brand: "",
    description: "",
    image: "/assets/imports/Balkancar_logo_1.jpg",
    id: 1160,
  },
  {
    name: "Baoli",
    category: "Import-Export",
    brand: "",
    description: "",
    image: "/assets/imports/Baoli_logo_1.jpg",
    id: 1161,
  },
  {
    name: "BattioniePagani",
    category: "Import-Export",
    brand: "",
    description: "",
    image: "/assets/imports/Battioni_e_Pagani_logo_1.jpg",
    id: 1162,
  },
  {
    name: "Baumann",
    category: "Import-Export",
    brand: "",
    description: "",
    image: "/assets/imports/Baumann_logo_1.jpg",
    id: 1163,
  },
  {
    name: "Belet",
    category: "Import-Export",
    brand: "",
    description: "",
    image: "/assets/imports/Belet_logo_1.jpg",
    id: 1164,
  },
  {
    name: "Bobcat",
    category: "Import-Export",
    brand: "",
    description: "",
    image: "/assets/imports/Bobcat_logo_1.jpg",
    id: 1165,
  },
  {
    name: "Bolzoni",
    category: "Import-Export",
    brand: "",
    description: "",
    image: "/assets/imports/Bolzoni_logo_1.jpg",
    id: 1166,
  },
  {
    name: "Boss",
    category: "Import-Export",
    brand: "",
    description: "",
    image: "/assets/imports/Boss_logo_1.jpg",
    id: 1167,
  },
  {
    name: "Brunilift",
    category: "Import-Export",
    brand: "",
    description: "",
    image: "/assets/imports/Brunilift_logo_1.jpg",
    id: 1168,
  },
  {
    name: "BT",
    category: "Import-Export",
    brand: "",
    description: "",
    image: "/assets/imports/BT_logo_1.jpg",
    id: 1169,
  },
  {
    name: "BulliKahl",
    category: "Import-Export",
    brand: "",
    description: "",
    image: "/assets/imports/Bulli_Kahl_logo_1.jpg",
    id: 1170,
  },
  {
    name: "BVVestergaard",
    category: "Import-Export",
    brand: "",
    description: "",
    image: "/assets/imports/BV_Vestergaard_logo_1.jpg",
    id: 1171,
  },
  {
    name: "Carer",
    category: "Import-Export",
    brand: "",
    description: "",
    image: "/assets/imports/Carer_logo_1.jpg",
    id: 1172,
  },
  {
    name: "Carreffe",
    category: "Import-Export",
    brand: "",
    description: "",
    image: "/assets/imports/Carreffe_logo_1.jpg",
    id: 1173,
  },
  {
    name: "Charlatte",
    category: "Import-Export",
    brand: "",
    description: "",
    image: "/assets/imports/Charlatte_logo_1.jpg",
    id: 1174,
  },
  {
    name: "Comac",
    category: "Import-Export",
    brand: "",
    description: "",
    image: "/assets/imports/Comac_logo_1.jpg",
    id: 1175,
  },
  {
    name: "CoventryClimax",
    category: "Import-Export",
    brand: "",
    description: "",
    image: "/assets/imports/Coventry_Climax_logo_1.jpg",
    id: 1176,
  },
  {
    name: "Crown",
    category: "Import-Export",
    brand: "",
    description: "",
    image: "/assets/imports/Crown_logo_1.jpg",
    id: 1177,
  },
  {
    name: "CTC",
    category: "Import-Export",
    brand: "",
    description: "",
    image: "/assets/imports/CTC_logo_1.jpg",
    id: 1178,
  },
  {
    name: "CVSFerrari",
    category: "Import-Export",
    brand: "",
    description: "",
    image: "/assets/imports/CVS_Ferrari_logo_1.jpg",
    id: 1179,
  },
  {
    name: "Dalian",
    category: "Import-Export",
    brand: "",
    description: "",
    image: "/assets/imports/Dalian_logo_1.jpg",
    id: 1180,
  },
  {
    name: "Dambach",
    category: "Import-Export",
    brand: "",
    description: "",
    image: "/assets/imports/Dambach_logo_1.jpg",
    id: 1181,
  },
  {
    name: "Dantruck",
    category: "Import-Export",
    brand: "",
    description: "",
    image: "/assets/imports/Dantruck_logo_1.jpg",
    id: 1182,
  },
  {
    name: "Ecolift",
    category: "Import-Export",
    brand: "",
    description: "",
    image: "/assets/imports/Ecolift_logo_1.jpg",
    id: 1183,
  },
  {
    name: "EP",
    category: "Import-Export",
    brand: "",
    description: "",
    image: "/assets/imports/EP_logo_1.jpg",
    id: 1184,
  },
  {
    name: "Fantuzzi",
    category: "Import-Export",
    brand: "",
    description: "",
    image: "/assets/imports/Fantuzzi_logo_1.jpg",
    id: 1185,
  },
  {
    name: "Harlan",
    category: "Import-Export",
    brand: "",
    description: "",
    image: "/assets/imports/Harlan_logo_1.jpg",
    id: 1186,
  },
  {
    name: "Heden-Dantruck",
    category: "Import-Export",
    brand: "",
    description: "",
    image: "/assets/imports/Heden_Dantruck_logo_1.jpg",
    id: 1187,
  },
  {
    name: "Heli",
    category: "Import-Export",
    brand: "",
    description: "",
    image: "/assets/imports/Heli_logo_1.jpg",
    id: 1188,
  },
  {
    name: "Indos",
    category: "Import-Export",
    brand: "",
    description: "",
    image: "/assets/imports/Indos_logo_1.jpg",
    id: 1189,
  },
  {
    name: "Italmacchine",
    category: "Import-Export",
    brand: "",
    description: "",
    image: "/assets/imports/Italmacchine_logo_1.jpg",
    id: 1190,
  },
  {
    name: "JLG",
    category: "Import-Export",
    brand: "",
    description: "",
    image: "/assets/imports/JLG_logo_1.jpg",
    id: 1191,
  },
  {
    name: "Jumbo",
    category: "Import-Export",
    brand: "",
    description: "",
    image: "/assets/imports/Jumbo_logo_1.jpg",
    id: 1192,
  },
  {
    name: "KalmarAC",
    category: "Import-Export",
    brand: "",
    description: "",
    image: "/assets/imports/Kalmar_AC_logo_1.jpg",
    id: 1193,
  },
  {
    name: "K\u00e4rcher",
    category: "Import-Export",
    brand: "",
    description: "",
    image: "/assets/imports/K\u00e4rcher_logo_1.jpg",
    id: 1194,
  },
  {
    name: "Kentruck-Robur",
    category: "Import-Export",
    brand: "",
    description: "",
    image: "/assets/imports/Kentruck-Robur_logo_1.jpg",
    id: 1195,
  },
  {
    name: "LancerBoss",
    category: "Import-Export",
    brand: "",
    description: "",
    image: "/assets/imports/Lancer_Boss_logo_1.jpg",
    id: 1196,
  },
  {
    name: "Lifter",
    category: "Import-Export",
    brand: "",
    description: "",
    image: "/assets/imports/Lifter_logo_1.jpg",
    id: 1197,
  },
  {
    name: "Loc",
    category: "Import-Export",
    brand: "",
    description: "",
    image: "/assets/imports/Loc_logo_1.jpg",
    id: 1198,
  },
  {
    name: "MBB",
    category: "Import-Export",
    brand: "",
    description: "",
    image: "/assets/imports/MBB_logo_1.jpg",
    id: 1199,
  },
  {
    name: "MIC",
    category: "Import-Export",
    brand: "",
    description: "",
    image: "/assets/imports/MIC_logo_1.jpg",
    id: 1200,
  },
  {
    name: "Mitsubishi",
    category: "Import-Export",
    brand: "",
    description: "",
    image: "/assets/imports/Mitsubishi_logo_1.jpg",
    id: 1201,
  },
  {
    name: "Moffett",
    category: "Import-Export",
    brand: "",
    description: "",
    image: "/assets/imports/Moffett_logo_1.jpg",
    id: 1202,
  },
  {
    name: "Montini",
    category: "Import-Export",
    brand: "",
    description: "",
    image: "/assets/imports/Montini_logo_1.jpg",
    id: 1203,
  },
  {
    name: "Mora",
    category: "Import-Export",
    brand: "",
    description: "",
    image: "/assets/imports/Mora_logo_1.jpg",
    id: 1204,
  },
  {
    name: "Nilfisk-Advance",
    category: "Import-Export",
    brand: "",
    description: "",
    image: "/assets/imports/Nilfisk-Advance_logo_1.jpg",
    id: 1205,
  },
  {
    name: "NuovaDetas",
    category: "Import-Export",
    brand: "",
    description: "",
    image: "/assets/imports/Nuova_Detas_logo_1.jpg",
    id: 1206,
  },
  {
    name: "O&K",
    category: "Import-Export",
    brand: "",
    description: "",
    image: "/assets/imports/O&K_logo_1.jpg",
    id: 1207,
  },
  {
    name: "OMG",
    category: "Import-Export",
    brand: "",
    description: "",
    image: "/assets/imports/OMG_logo_1.jpg",
    id: 1208,
  },
  {
    name: "Powerboss",
    category: "Import-Export",
    brand: "",
    description: "",
    image: "/assets/imports/Powerboss_logo_1.jpg",
    id: 1209,
  },
  {
    name: "Pramac",
    category: "Import-Export",
    brand: "",
    description: "",
    image: "/assets/imports/Pramac_logo_1.jpg",
    id: 1210,
  },
  {
    name: "Salev",
    category: "Import-Export",
    brand: "",
    description: "",
    image: "/assets/imports/Salev_logo_1.jpg",
    id: 1211,
  },
  {
    name: "Samsung",
    category: "Import-Export",
    brand: "",
    description: "",
    image: "/assets/imports/Samsung_logo_1.jpg",
    id: 1212,
  },
  {
    name: "SMVKonecranes",
    category: "Import-Export",
    brand: "",
    description: "",
    image: "/assets/imports/SMV_Konecranes_logo_1.jpg",
    id: 1213,
  },
  {
    name: "Tailift",
    category: "Import-Export",
    brand: "",
    description: "",
    image: "/assets/imports/Tailift_logo_1.jpg",
    id: 1214,
  },
  {
    name: "Ausa",
    category: "Import-Export",
    brand: "",
    description: "",
    image: "/assets/imports/Ausa_logo_1.jpg",
    id: 1215,
  },
  {
    name: "Bonser",
    category: "Import-Export",
    brand: "",
    description: "",
    image: "/assets/imports/Bonser_logo_1.jpg",
    id: 1216,
  },
  {
    name: "Cangaru",
    category: "Import-Export",
    brand: "",
    description: "",
    image: "/assets/imports/Cangaru_logo_1.jpg",
    id: 1217,
  },
  {
    name: "Caterpillar",
    category: "Import-Export",
    brand: "",
    description: "",
    image: "/assets/imports/Caterpillar_logo_1.jpg",
    id: 1218,
  },
  {
    name: "Cesab",
    category: "Import-Export",
    brand: "",
    description: "",
    image: "/assets/imports/Cesab_logo_1.jpg",
    id: 1219,
  },
  {
    name: "Clark",
    category: "Import-Export",
    brand: "",
    description: "",
    image: "/assets/imports/Clark_logo_1.jpg",
    id: 1220,
  },
  {
    name: "Conveyancer",
    category: "Import-Export",
    brand: "",
    description: "",
    image: "/assets/imports/Conveyancer_logo_1.jpg",
    id: 1221,
  },
  {
    name: "Datsun",
    category: "Import-Export",
    brand: "",
    description: "",
    image: "/assets/imports/Datsun_logo_1.jpg",
    id: 1222,
  },
  {
    name: "Deca",
    category: "Import-Export",
    brand: "",
    description: "",
    image: "/assets/imports/Deca_logo_1.jpg",
    id: 1223,
  },
  {
    name: "Desta",
    category: "Import-Export",
    brand: "",
    description: "",
    image: "/assets/imports/Desta_logo_1.jpg",
    id: 1224,
  },
  {
    name: "Dhollandia",
    category: "Import-Export",
    brand: "",
    description: "",
    image: "/assets/imports/Dhollandia_logo_1.jpg",
    id: 1225,
  },
  {
    name: "Dieci",
    category: "Import-Export",
    brand: "",
    description: "",
    image: "/assets/imports/Dieci_logo_1.jpg",
    id: 1226,
  },
  {
    name: "DinoLift",
    category: "Import-Export",
    brand: "",
    description: "",
    image: "/assets/imports/Dino_Lift_logo_1.jpg",
    id: 1227,
  },
  {
    name: "Dulevo",
    category: "Import-Export",
    brand: "",
    description: "",
    image: "/assets/imports/Dulevo_logo_1.jpg",
    id: 1228,
  },
  {
    name: "Eurolifter",
    category: "Import-Export",
    brand: "",
    description: "",
    image: "/assets/imports/Eurolifter_logo_1.jpg",
    id: 1229,
  },
  {
    name: "Faba",
    category: "Import-Export",
    brand: "",
    description: "",
    image: "/assets/imports/Faba_logo_1.jpg",
    id: 1230,
  },
  {
    name: "Fenwick",
    category: "Import-Export",
    brand: "",
    description: "",
    image: "/assets/imports/Fenwick_logo_1.jpg",
    id: 1231,
  },
  {
    name: "Genie",
    category: "Import-Export",
    brand: "",
    description: "",
    image: "/assets/imports/Genie_logo_1.jpg",
    id: 1232,
  },
  {
    name: "Genkinger",
    category: "Import-Export",
    brand: "",
    description: "",
    image: "/assets/imports/Genkinger_logo_1.jpg",
    id: 1233,
  },
  {
    name: "Grove",
    category: "Import-Export",
    brand: "",
    description: "",
    image: "/assets/imports/Grove_logo_1.jpg",
    id: 1234,
  },
  {
    name: "Hako",
    category: "Import-Export",
    brand: "",
    description: "",
    image: "/assets/imports/Hako_logo_1.jpg",
    id: 1235,
  },
  {
    name: "Halla",
    category: "Import-Export",
    brand: "",
    description: "",
    image: "/assets/imports/Halla_logo_1.jpg",
    id: 1236,
  },
  {
    name: "Hangcha(HC)",
    category: "Import-Export",
    brand: "",
    description: "",
    image: "/assets/imports/Hangcha_(HC)_logo_1.jpg",
    id: 1237,
  },
  {
    name: "Haulotte",
    category: "Import-Export",
    brand: "",
    description: "",
    image: "/assets/imports/Haulotte_logo_1.jpg",
    id: 1238,
  },
  {
    name: "Henley",
    category: "Import-Export",
    brand: "",
    description: "",
    image: "/assets/imports/Henley_logo_1.jpg",
    id: 1239,
  },
  {
    name: "Hyster",
    category: "Import-Export",
    brand: "",
    description: "",
    image: "/assets/imports/Hyster_logo_1.jpg",
    id: 1240,
  },
  {
    name: "Hyundai",
    category: "Import-Export",
    brand: "",
    description: "",
    image: "/assets/imports/Hyundai_logo_1.jpg",
    id: 1241,
  },
  {
    name: "Icem",
    category: "Import-Export",
    brand: "",
    description: "",
    image: "/assets/imports/Icem_logo_1.jpg",
    id: 1242,
  },
  {
    name: "Incab",
    category: "Import-Export",
    brand: "",
    description: "",
    image: "/assets/imports/Incab_logo_1.jpg",
    id: 1243,
  },
  {
    name: "JCB",
    category: "Import-Export",
    brand: "",
    description: "",
    image: "/assets/imports/JCB_logo_1.jpg",
    id: 1244,
  },
  {
    name: "Jungheinrich",
    category: "Import-Export",
    brand: "",
    description: "",
    image: "/assets/imports/Jungheinrich_logo_1.jpg",
    id: 1245,
  },
  {
    name: "Kalmar",
    category: "Import-Export",
    brand: "",
    description: "",
    image: "/assets/imports/Kalmar_logo_1.jpg",
    id: 1246,
  },
  {
    name: "KinglifterTerberg",
    category: "Import-Export",
    brand: "",
    description: "",
    image: "/assets/imports/Kinglifter_Terberg_logo_1.jpg",
    id: 1247,
  },
  {
    name: "Komatsu",
    category: "Import-Export",
    brand: "",
    description: "",
    image: "/assets/imports/Komatsu_logo_1.jpg",
    id: 1248,
  },
  {
    name: "KooiAap",
    category: "Import-Export",
    brand: "",
    description: "",
    image: "/assets/imports/Kooi_Aap_logo_1.jpg",
    id: 1249,
  },
  {
    name: "Lafis",
    category: "Import-Export",
    brand: "",
    description: "",
    image: "/assets/imports/Lafis_logo_1.jpg",
    id: 1250,
  },
  {
    name: "Lansing",
    category: "Import-Export",
    brand: "",
    description: "",
    image: "/assets/imports/Lansing_logo_1.jpg",
    id: 1251,
  },
  {
    name: "Linde",
    category: "Import-Export",
    brand: "",
    description: "",
    image: "/assets/imports/Linde_logo_1.jpg",
    id: 1252,
  },
  {
    name: "Logitrans",
    category: "Import-Export",
    brand: "",
    description: "",
    image: "/assets/imports/Logitrans_logo_1.jpg",
    id: 1253,
  },
  {
    name: "Lugli",
    category: "Import-Export",
    brand: "",
    description: "",
    image: "/assets/imports/Lugli_logo_1.jpg",
    id: 1254,
  },
  {
    name: "Magliner",
    category: "Import-Export",
    brand: "",
    description: "",
    image: "/assets/imports/Magliner_logo_1.jpg",
    id: 1255,
  },
  {
    name: "Manitou",
    category: "Import-Export",
    brand: "",
    description: "",
    image: "/assets/imports/Manitou_logo_1.jpg",
    id: 1256,
  },
  {
    name: "Merlo",
    category: "Import-Export",
    brand: "",
    description: "",
    image: "/assets/imports/Merlo_logo_1.jpg",
    id: 1257,
  },
  {
    name: "Miag",
    category: "Import-Export",
    brand: "",
    description: "",
    image: "/assets/imports/Miag_logo_1.jpg",
    id: 1258,
  },
  {
    name: "Minuteman",
    category: "Import-Export",
    brand: "",
    description: "",
    image: "/assets/imports/Minuteman_logo_1.jpg",
    id: 1259,
  },
  {
    name: "Nichiyu-Nyk",
    category: "Import-Export",
    brand: "",
    description: "",
    image: "/assets/imports/Nichiyu-Nyk_logo_1.jpg",
    id: 1260,
  },
  {
    name: "Niftylift",
    category: "Import-Export",
    brand: "",
    description: "",
    image: "/assets/imports/Niftylift_logo_1.jpg",
    id: 1261,
  },
  {
    name: "Nissan",
    category: "Import-Export",
    brand: "",
    description: "",
    image: "/assets/imports/Nissan_logo_1.jpg",
    id: 1262,
  },
  {
    name: "Noblelift",
    category: "Import-Export",
    brand: "",
    description: "",
    image: "/assets/imports/Noblelift_logo_1.jpg",
    id: 1263,
  },
  {
    name: "Patria",
    category: "Import-Export",
    brand: "",
    description: "",
    image: "/assets/imports/Patria_logo_1.jpg",
    id: 1264,
  },
  {
    name: "Peg-Fenwick",
    category: "Import-Export",
    brand: "",
    description: "",
    image: "/assets/imports/Peg-Fenwick_logo_1.jpg",
    id: 1265,
  },
  {
    name: "Pimespo",
    category: "Import-Export",
    brand: "",
    description: "",
    image: "/assets/imports/Pimespo_logo_1.jpg",
    id: 1266,
  },
  {
    name: "Prat",
    category: "Import-Export",
    brand: "",
    description: "",
    image: "/assets/imports/Prat_logo_1.jpg",
    id: 1267,
  },
  {
    name: "Ranger",
    category: "Import-Export",
    brand: "",
    description: "",
    image: "/assets/imports/Ranger_logo_1.jpg",
    id: 1268,
  },
  {
    name: "RCM",
    category: "Import-Export",
    brand: "",
    description: "",
    image: "/assets/imports/RCM_logo_1.jpg",
    id: 1269,
  },
  {
    name: "Rocla",
    category: "Import-Export",
    brand: "",
    description: "",
    image: "/assets/imports/Rocla_logo_1.jpg",
    id: 1270,
  },
  {
    name: "Samag",
    category: "Import-Export",
    brand: "",
    description: "",
    image: "/assets/imports/Samag_logo_1.jpg",
    id: 1271,
  },
  {
    name: "Sambron",
    category: "Import-Export",
    brand: "",
    description: "",
    image: "/assets/imports/Sambron_logo_1.jpg",
    id: 1272,
  },
  {
    name: "Sanderson",
    category: "Import-Export",
    brand: "",
    description: "",
    image: "/assets/imports/Sanderson_logo_1.jpg",
    id: 1273,
  },
  {
    name: "Saxby",
    category: "Import-Export",
    brand: "",
    description: "",
    image: "/assets/imports/Saxby_logo_1.jpg",
    id: 1274,
  },
  {
    name: "Sichelschmidt",
    category: "Import-Export",
    brand: "",
    description: "",
    image: "/assets/imports/Sichelschmidt_logo_1.jpg",
    id: 1275,
  },
  {
    name: "Sisu",
    category: "Import-Export",
    brand: "",
    description: "",
    image: "/assets/imports/Sisu_logo_1.jpg",
    id: 1276,
  },
  {
    name: "Skyjack",
    category: "Import-Export",
    brand: "",
    description: "",
    image: "/assets/imports/Skyjack_logo_1.jpg",
    id: 1277,
  },
  {
    name: "Steinbock",
    category: "Import-Export",
    brand: "",
    description: "",
    image: "/assets/imports/Steinbock_logo_1.jpg",
    id: 1278,
  },
  {
    name: "Still",
    category: "Import-Export",
    brand: "",
    description: "",
    image: "/assets/imports/Still_logo_1.jpg",
    id: 1279,
  },
  {
    name: "Stocka",
    category: "Import-Export",
    brand: "",
    description: "",
    image: "/assets/imports/Stocka_logo_1.jpg",
    id: 1280,
  },
  {
    name: "Stocklin",
    category: "Import-Export",
    brand: "",
    description: "",
    image: "/assets/imports/Stocklin_logo_1.jpg",
    id: 1281,
  },
  {
    name: "Svetruck",
    category: "Import-Export",
    brand: "",
    description: "",
    image: "/assets/imports/Svetruck_logo_1.jpg",
    id: 1282,
  },
  {
    name: "TCM",
    category: "Import-Export",
    brand: "",
    description: "",
    image: "/assets/imports/TCM_logo_1.jpg",
    id: 1283,
  },
  {
    name: "Tecnocar",
    category: "Import-Export",
    brand: "",
    description: "",
    image: "/assets/imports/Tecnocar_logo_1.jpg",
    id: 1284,
  },
  {
    name: "Tennant",
    category: "Import-Export",
    brand: "",
    description: "",
    image: "/assets/imports/Tennant_logo_1.jpg",
    id: 1285,
  },
  {
    name: "Terex",
    category: "Import-Export",
    brand: "",
    description: "",
    image: "/assets/imports/Terex_logo_1.jpg",
    id: 1286,
  },
  {
    name: "Totallifter",
    category: "Import-Export",
    brand: "",
    description: "",
    image: "/assets/imports/Totallifter_logo_1.jpg",
    id: 1287,
  },
  {
    name: "Toyota",
    category: "Import-Export",
    brand: "",
    description: "",
    image: "/assets/imports/Toyota_logo_1.jpg",
    id: 1288,
  },
  {
    name: "INFICON D-TEK 3 Refrigerant Leak Detector",
    category: "HVAC & Air Conditioning",
    brand: "",
    description: "",
    image: "/assets/hvac/INFICON-D-TEK-3.jpg",
    id: 1289,
  },
  {
    name: "INFICON D-TEK Stratus Refrigerant Leak Detector",
    category: "HVAC & Air Conditioning",
    brand: "",
    description: "",
    image: "/assets/hvac/stratus-refri.jpg",
    id: 1290,
  },
  {
    name: "INFICON Leak Detector",
    category: "HVAC & Air Conditioning",
    brand: "",
    description: "",
    image: "/assets/hvac/leak-detetctor.jpg",
    id: 1291,
  },
  {
    name: "INFICON Pilot Plus vacuum gauge",
    category: "HVAC & Air Conditioning",
    brand: "",
    description: "",
    image: "/assets/hvac/vacuum-gauge.jpg",
    id: 1292,
  },
  {
    name: "INFICON Tek-Mate Refrigerant Leak Detector",
    category: "HVAC & Air Conditioning",
    brand: "",
    description: "",
    image: "/assets/hvac/take-mate-leak.jpg",
    id: 1293,
  },
  {
    name: "INFICON Vortex Dual Refrigerant Recovery Machine",
    category: "HVAC & Air Conditioning",
    brand: "",
    description: "",
    image: "/assets/hvac/recovery-mach.png",
    id: 1294,
  },

  {
    name: "INFICON Wey-tek HD Wireless Charging Scale with Handpiece",
    category: "HVAC & Air Conditioning",
    brand: "",
    description: "",
    image: "/assets/hvac/wireless-charging.png",
    id: 1295,
  },
  {
    name: "INFICON WeyTek weighing scale",
    category: "HVAC & Air Conditioning",
    brand: "",
    description: "",
    image: "/assets/hvac/weighing-scale.webp",
    id: 1296,
  },
  {
    name: "INFICON Whisper ultrasonic leak detector",
    category: "HVAC & Air Conditioning",
    brand: "",
    description: "",
    image: "/assets/hvac/ultra-leak-detector.webp",
    id: 1297,
  },
  {
    name: "Axial Fan Motors – Single Phase",
    category: "HVAC & Air Conditioning",
    brand: "",
    description: "",
    image: "/assets/hvac/Axial_Fan_Motors_–_Single_Phase.jpg",
    id: 1298,
  },

  {
    name: "Axial Fan Motors – Three Phase",
    category: "HVAC & Air Conditioning",
    brand: "",
    description: "",
    image: "/assets/hvac/Axial_Fan_Motors_–_Three_Phase.jpg",
    id: 1299,
  },

  {
    name: "Condenser motor- Double Shaft Replace Fan Motor Air Conditioner 1/3HP 245W 115V",
    category: "HVAC & Air Conditioning",
    brand: "",
    description: "",
    image:
      "/assets/hvac/Condenser_motor-_Double_Shaft_Replace_Fan_Motor_Air_Conditioner_1_3HP_245W_115V.jpg",
    id: 1300,
  },

  {
    name: "Condenser motor- HVAC Blower Motor Replacement, 1/6HP Air Condenser Fan Motor",
    category: "HVAC & Air Conditioning",
    brand: "",
    description: "",
    image:
      "/assets/hvac/Condenser_motor-_HVAC_Blower_Motor_Replacement,_1_6HP_Air_Condenser_Fan_Motor.jpg",
    id: 1301,
  },

  {
    name: "Condenser motors- 115V 120W Variable Speed Condenser Fan Motor reversible Rotation",
    category: "HVAC & Air Conditioning",
    brand: "",
    description: "",
    image:
      "/assets/hvac/Condenser_motors-_115V_120W_Variable_Speed_Condenser_Fan_Motor_reversible_Rotation.jpg",
    id: 1302,
  },

  {
    name: "Condenser motors- 4 Wire Condenser Fan Motor Variable Speed , HVAC Condenser Motor",
    category: "HVAC & Air Conditioning",
    brand: "",
    description: "",
    image:
      "/assets/hvac/Condenser_motors-_4_Wire_Condenser_Fan_Motor_Variable_Speed_,_HVAC_Condenser_Motor.jpg",
    id: 1303,
  },

  {
    name: "Condenser motors-1/5HP 60Hz Condenser Fan Motors NSK low noise High performance Ball Bearing",
    category: "HVAC & Air Conditioning",
    brand: "",
    description: "",
    image:
      "/assets/hvac/Condenser_motors-1_5HP_60Hz_Condenser_Fan_Motors_NSK_low_noise_High_performance_Ball_Bearing.jpg",
    id: 1304,
  },

  {
    name: "FMI Motors",
    category: "HVAC & Air Conditioning",
    brand: "",
    description: "",
    image: "/assets/hvac/FMI_Motors.jpg",
    id: 1305,
  },

  {
    name: "Genteq motor",
    category: "HVAC & Air Conditioning",
    brand: "",
    description: "",
    image: "/assets/hvac/Genteq_motor.jpg",
    id: 1306,
  },

  {
    name: "Kulthorn Motors",
    category: "HVAC & Air Conditioning",
    brand: "",
    description: "",
    image: "/assets/hvac/Kulthorn_Motors.jpg",
    id: 1307,
  },

  {
    name: "Universal AC motor",
    category: "HVAC & Air Conditioning",
    brand: "",
    description: "",
    image: "/assets/hvac/Universal_AC_motor.jpg",
    id: 1308,
  },
  {
    name: "Aluminum & Plastic Blower",
    category: "HVAC & Air Conditioning",
    brand: "",
    description: "",
    image: "/assets/hvac/Aluminum_&_Plastic_Blower.jpg",
    id: 1309,
  },

  {
    name: "Split AC blower",
    category: "HVAC & Air Conditioning",
    brand: "",
    description: "",
    image: "/assets/hvac/Split_AC_blower.jpg",
    id: 1310,
  },
  {
    name: "Capacitors -India",
    category: "HVAC & Air Conditioning",
    brand: "",
    description: "",
    image: "/assets/hvac/Capacitors_-India.jpg",
    id: 1311,
  },

  {
    name: "Capacitors-China",
    category: "HVAC & Air Conditioning",
    brand: "",
    description: "",
    image: "/assets/hvac/Capacitors-China.jpg",
    id: 1312,
  },
  {
    name: "Aluminium tape",
    category: "HVAC & Air Conditioning",
    brand: "",
    description: "",
    image: "/assets/hvac/Aluminium_tape.jpg",
    id: 1313,
  },

  {
    name: "Canvas cloth",
    category: "HVAC & Air Conditioning",
    brand: "",
    description: "",
    image: "/assets/hvac/Canvas_cloth.jpg",
    id: 1314,
  },

  {
    name: "Exhaust fan motor – Vents",
    category: "HVAC & Air Conditioning",
    brand: "",
    description: "",
    image: "/assets/hvac/Exhaust_fan_motor_–_Vents.jpg",
    id: 1315,
  },

  {
    name: "Exhaust fan motor-Soler& Palau S&P TD-100 Inline Duct Exhaust Fan",
    category: "HVAC & Air Conditioning",
    brand: "",
    description: "",
    image:
      "/assets/hvac/Exhaust_fan_motor-Soler&_Palau_S&P_TD-100_Inline_Duct_Exhaust_Fan.jpg",
    id: 1316,
  },

  {
    name: "Flexible duct- PVC Flexible Duct",
    category: "HVAC & Air Conditioning",
    brand: "",
    description: "",
    image: "/assets/hvac/Flexible_duct-_PVC_Flexible_Duct.jpg",
    id: 1317,
  },

  {
    name: "Flexible duct-Fire Retardant Flexible Duct",
    category: "HVAC & Air Conditioning",
    brand: "",
    description: "",
    image: "/assets/hvac/Flexible_duct-Fire_Retardant_Flexible_Duct.jpg",
    id: 1318,
  },

  {
    name: "Foster 30-36",
    category: "HVAC & Air Conditioning",
    brand: "",
    description: "",
    image: "/assets/hvac/Foster_30-36.jpg",
    id: 1319,
  },

  {
    name: "PRE insulated sheet-Phenolic Pre-Insulated Duct Sheet Panel Air Duct",
    category: "HVAC & Air Conditioning",
    brand: "",
    description: "",
    image:
      "/assets/hvac/PRE_insulated_sheet-Phenolic_Pre-Insulated_Duct_Sheet_Panel_Air_Duct.jpg",
    id: 1320,
  },
  {
    name: "Oxygen and Acetylene regulators & fittings",
    category: "HVAC & Air Conditioning",
    brand: "",
    description: "",
    image: "/assets/hvac/Oxygen_and_Acetylene_regulators_&_fittings.jpg",
    id: 1321,
  },

  {
    name: "Venus set welding tools",
    category: "HVAC & Air Conditioning",
    brand: "",
    description: "",
    image: "/assets/hvac/Venus_set_welding_tools.jpg",
    id: 1322,
  },

  {
    name: "Victor set welding tools",
    category: "HVAC & Air Conditioning",
    brand: "",
    description: "",
    image: "/assets/hvac/Victor_set_welding_tools.jpg",
    id: 1323,
  },
  {
    name: "R134A Refrigerant Gas",
    category: "HVAC & Air Conditioning",
    brand: "",
    description: "",
    image: "/assets/hvac/R134A_Refrigerant_Gas.jpg",
    id: 1324,
  },

  {
    name: "R22 Refrigerant Gas",
    category: "HVAC & Air Conditioning",
    brand: "",
    description: "",
    image: "/assets/hvac/R22_Refrigerant_Gas.jpg",
    id: 1325,
  },

  {
    name: "R404A Refrigerant Gas",
    category: "HVAC & Air Conditioning",
    brand: "",
    description: "",
    image: "/assets/hvac/R404A_Refrigerant_Gas.jpg",
    id: 1326,
  },

  {
    name: "R407C Refrigerant Gas",
    category: "HVAC & Air Conditioning",
    brand: "",
    description: "",
    image: "/assets/hvac/R407C_Refrigerant_Gas.jpg",
    id: 1327,
  },

  {
    name: "R410A Refrigerant Gas",
    category: "HVAC & Air Conditioning",
    brand: "",
    description: "",
    image: "/assets/hvac/R410A_Refrigerant_Gas.jpg",
    id: 1328,
  },
  {
    name: "Honeywell DP08-Thermostat Honeywell T6812DP08",
    category: "HVAC & Air Conditioning",
    brand: "",
    description: "",
    image: "/assets/hvac/Honeywell_DP08-Thermostat_Honeywell_T6812DP08.jpg",
    id: 1329,
  },

  {
    name: "Honeywell PRO1000 -Thermostat Honeywell PRO1000 Non-Programmable",
    category: "HVAC & Air Conditioning",
    brand: "",
    description: "",
    image:
      "/assets/hvac/Honeywell_PRO1000_-Thermostat_Honeywell_PRO1000_Non-Programmable.jpg",
    id: 1330,
  },

  {
    name: "Honeywell thermostat- Honeywell TH1100DH1004 Horizontal PRO 1000 Non-Programmable Thermostat Heat Only Dual Powered Backlit",
    category: "HVAC & Air Conditioning",
    brand: "",
    description: "",
    image:
      "/assets/hvac/Honeywell_thermostat-_Honeywell_TH1100DH1004_Horizontal_PRO_1000_Non-Programmable_Thermostat_Heat_Only_Dual_Powered_Backlit.jpg",
    id: 1331,
  },

  {
    name: "Honeywell thermostat-PRO 1000 VERTICAL HEAT NON-PROGRAMMABLE THERMOSTAT",
    category: "HVAC & Air Conditioning",
    brand: "",
    description: "",
    image:
      "/assets/hvac/Honeywell_thermostat-PRO_1000_VERTICAL_HEAT_NON-PROGRAMMABLE_THERMOSTAT.jpg",
    id: 1332,
  },
  {
    name: "AKO-D14123 230V 1 relay refrigeration control System",
    category: "HVAC & Air Conditioning",
    brand: "",
    description: "",
    image:
      "/assets/hvac/AKO-D14123_230V_1_relay_refrigeration_control_System.jpg",
    id: 1333,
  },

  {
    name: "Dixell Refrigeration control system",
    category: "HVAC & Air Conditioning",
    brand: "",
    description: "",
    image: "/assets/hvac/Dixell_Refrigeration_control_system.jpg",
    id: 1334,
  },

  {
    name: "Eliwell Refrigeration Controller",
    category: "HVAC & Air Conditioning",
    brand: "",
    description: "",
    image: "/assets/hvac/Eliwell_Refrigeration_Controller.jpg",
    id: 1335,
  },

  {
    name: "STC-100A+ Refrigeration Controller",
    category: "HVAC & Air Conditioning",
    brand: "",
    description: "",
    image: "/assets/hvac/STC-100A+_Refrigeration_Controller.jpg",
    id: 1336,
  },
  {
    name: "Adhesives, Sealants & Coatings",
    category: "HVAC & Air Conditioning",
    brand: "",
    description: "",
    image: "/assets/hvac/Adhesives,_Sealants_&_Coatings.jpg",
    id: 1337,
  },

  {
    name: "Closed Cell Rubber Insulation",
    category: "HVAC & Air Conditioning",
    brand: "",
    description: "",
    image: "/assets/hvac/Closed_Cell_Rubber_Insulation.jpg",
    id: 1338,
  },

  {
    name: "Compressors & Expansion Valves",
    category: "HVAC & Air Conditioning",
    brand: "",
    description: "",
    image: "/assets/hvac/Compressors_&_Expansion_Valves.jpg",
    id: 1339,
  },

  {
    name: "Copper Products",
    category: "HVAC & Air Conditioning",
    brand: "",
    description: "",
    image: "/assets/hvac/Copper_Products.jpg",
    id: 1340,
  },

  {
    name: "Duct Accessories",
    category: "HVAC & Air Conditioning",
    brand: "",
    description: "",
    image: "/assets/hvac/Duct_Accessories.jpg",
    id: 1341,
  },

  {
    name: "Fiberglass Insulation",
    category: "HVAC & Air Conditioning",
    brand: "",
    description: "",
    image: "/assets/hvac/Fiberglass_Insulation.jpg",
    id: 1342,
  },

  {
    name: "Heat Exchangers",
    category: "HVAC & Air Conditioning",
    brand: "",
    description: "",
    image: "/assets/hvac/Heat_Exchangers.jpg",
    id: 1343,
  },

  {
    name: "HVAC & Plumbing Valves",
    category: "HVAC & Air Conditioning",
    brand: "",
    description: "",
    image: "/assets/hvac/HVAC_&_Plumbing_Valves.jpg",
    id: 1344,
  },

  {
    name: "HVAC Controls & Accessories",
    category: "HVAC & Air Conditioning",
    brand: "",
    description: "",
    image: "/assets/hvac/HVAC_Controls_&_Accessories.jpg",
    id: 1345,
  },

  {
    name: "MI Fittings",
    category: "HVAC & Air Conditioning",
    brand: "",
    description: "",
    image: "/assets/hvac/MI_Fittings.jpg",
    id: 1346,
  },

  {
    name: "Plumbing Speciality Valves",
    category: "HVAC & Air Conditioning",
    brand: "",
    description: "",
    image: "/assets/hvac/Plumbing_Speciality_Valves.jpg",
    id: 1347,
  },

  {
    name: "Pressure Gauges & Thermometers",
    category: "HVAC & Air Conditioning",
    brand: "",
    description: "",
    image: "/assets/hvac/Pressure_Gauges_&_Thermometers.jpg",
    id: 1348,
  },

  {
    name: "Pressure Independent Control Valves",
    category: "HVAC & Air Conditioning",
    brand: "",
    description: "",
    image: "/assets/hvac/Pressure_Independent_Control_Valves.jpg",
    id: 1349,
  },

  {
    name: "Pressurisation Units",
    category: "HVAC & Air Conditioning",
    brand: "",
    description: "",
    image: "/assets/hvac/Pressurisation_Units.jpg",
    id: 1350,
  },

  {
    name: "Pumps",
    category: "HVAC & Air Conditioning",
    brand: "",
    description: "",
    image: "/assets/hvac/Pumps.jpg",
    id: 1351,
  },

  {
    name: "Water Hammer Arrestors",
    category: "HVAC & Air Conditioning",
    brand: "",
    description: "",
    image: "/assets/hvac/Water_Hammer_Arrestors.jpg",
    id: 1352,
  },
  {
    name: "Acoustic Cabinet Fans-CAB Direct Driven Cabinet Fan",
    category: "HVAC & Air Conditioning",
    brand: "",
    description: "",
    image:
      "/assets/hvac/Acoustic_Cabinet_Fans-CAB_Direct_Driven_Cabinet_Fan.jpg",
    id: 1353,
  },

  {
    name: "Acoustic Cabinet Fans-CAB-E/EL Direct Driven Cabinet Fan",
    category: "HVAC & Air Conditioning",
    brand: "",
    description: "",
    image:
      "/assets/hvac/Acoustic_Cabinet_Fans-CAB-E_EL_Direct_Driven_Cabinet_Fan.jpg",
    id: 1354,
  },

  {
    name: "Acoustic Cabinet Fans-CVAB/CVAT Direct Driven Cabinet Fan",
    category: "HVAC & Air Conditioning",
    brand: "",
    description: "",
    image:
      "/assets/hvac/Acoustic_Cabinet_Fans-CVAB_CVAT_Direct_Driven_Cabinet_Fan.jpg",
    id: 1355,
  },

  {
    name: "Acoustic Cabinet Fans-CVB/CVT Direct Driven Cabinet Fan",
    category: "HVAC & Air Conditioning",
    brand: "",
    description: "",
    image:
      "/assets/hvac/Acoustic_Cabinet_Fans-CVB_CVT_Direct_Driven_Cabinet_Fan.jpg",
    id: 1356,
  },

  {
    name: "Acoustic Cabinet Fans-CVBX2/CVTTX2 Cabinet Twin Fan",
    category: "HVAC & Air Conditioning",
    brand: "",
    description: "",
    image:
      "/assets/hvac/Acoustic_Cabinet_Fans-CVBX2_CVTTX2_Cabinet_Twin_Fan.jpg",
    id: 1357,
  },

  {
    name: "Acoustic Cabinet Fans-CVTT Belt Driven Cabinet Fan",
    category: "HVAC & Air Conditioning",
    brand: "",
    description: "",
    image:
      "/assets/hvac/Acoustic_Cabinet_Fans-CVTT_Belt_Driven_Cabinet_Fan.jpg",
    id: 1358,
  },

  {
    name: "Acoustic Cabinet Fans-KABB/KABT Direct Driven Backward Curve Fan",
    category: "HVAC & Air Conditioning",
    brand: "",
    description: "",
    image:
      "/assets/hvac/Acoustic_Cabinet_Fans-KABB_KABT_Direct_Driven_Backward_Curve_Fan.jpg",
    id: 1359,
  },

  {
    name: "Centrifugal Fan-CBTR/CBTRL Backward Curved Single Inlet Fan",
    category: "HVAC & Air Conditioning",
    brand: "",
    description: "",
    image:
      "/assets/hvac/Centrifugal_Fan-CBTR_CBTRL_Backward_Curved_Single_Inlet_Fan.jpg",
    id: 1360,
  },

  {
    name: "Centrifugal Fan-CMB/CMT Forward Curved Single Inlet Fan",
    category: "HVAC & Air Conditioning",
    brand: "",
    description: "",
    image:
      "/assets/hvac/Centrifugal_Fan-CMB_CMT_Forward_Curved_Single_Inlet_Fan.jpg",
    id: 1361,
  },

  {
    name: "Centrifugal Fan-CRRT Backward Curved Single Inlet Direct Driven Fan",
    category: "HVAC & Air Conditioning",
    brand: "",
    description: "",
    image:
      "/assets/hvac/Centrifugal_Fan-CRRT_Backward_Curved_Single_Inlet_Direct_Driven_Fan.jpg",
    id: 1362,
  },

  {
    name: "Centrifugal Fan-FR-TR Backward Curved Single Inlet Belt Driven Fan",
    category: "HVAC & Air Conditioning",
    brand: "",
    description: "",
    image:
      "/assets/hvac/Centrifugal_Fan-FR-TR_Backward_Curved_Single_Inlet_Belt_Driven_Fan.jpg",
    id: 1363,
  },

  {
    name: "Smoke Extract Fans-CHVB/CHVT Backward Curve Cabinet Fan",
    category: "HVAC & Air Conditioning",
    brand: "",
    description: "",
    image:
      "/assets/hvac/Smoke_Extract_Fans-CHVB_CHVT_Backward_Curve_Cabinet_Fan.jpg",
    id: 1364,
  },

  {
    name: "Smoke Extract Fans-CTHB/CTHT Centrifugal Horizontal Discharge Fan",
    category: "HVAC & Air Conditioning",
    brand: "",
    description: "",
    image:
      "/assets/hvac/Smoke_Extract_Fans-CTHB_CTHT_Centrifugal_Horizontal_Discharge_Fan.jpg",
    id: 1365,
  },

  {
    name: "Smoke Extract Fans-CTVB/CTVT Centrifugal Up Blast Fan",
    category: "HVAC & Air Conditioning",
    brand: "",
    description: "",
    image:
      "/assets/hvac/Smoke_Extract_Fans-CTVB_CTVT_Centrifugal_Up_Blast_Fan.jpg",
    id: 1366,
  },

  {
    name: "Smoke Extract Fans-CVHM Forward Curved Cabinet Fan",
    category: "HVAC & Air Conditioning",
    brand: "",
    description: "",
    image:
      "/assets/hvac/Smoke_Extract_Fans-CVHM_Forward_Curved_Cabinet_Fan.jpg",
    id: 1367,
  },

  {
    name: "Smoke Extract Fans-CVHT Forward Curved Cabinet Fan",
    category: "HVAC & Air Conditioning",
    brand: "",
    description: "",
    image:
      "/assets/hvac/Smoke_Extract_Fans-CVHT_Forward_Curved_Cabinet_Fan.jpg",
    id: 1368,
  },

  {
    name: "Smoke Extract Fans-CVST Forward Curved Cabinet Fan",
    category: "HVAC & Air Conditioning",
    brand: "",
    description: "",
    image:
      "/assets/hvac/Smoke_Extract_Fans-CVST_Forward_Curved_Cabinet_Fan.jpg",
    id: 1369,
  },

  {
    name: "Smoke Extract Fans-HGHT-V Axial Flow Up Blast Fan",
    category: "HVAC & Air Conditioning",
    brand: "",
    description: "",
    image: "/assets/hvac/Smoke_Extract_Fans-HGHT-V_Axial_Flow_Up_Blast_Fan.jpg",
    id: 1370,
  },

  {
    name: "Smoke Extract Fans-THGT Long Cased/Short Cased Fan",
    category: "HVAC & Air Conditioning",
    brand: "",
    description: "",
    image:
      "/assets/hvac/Smoke_Extract_Fans-THGT_Long_Cased_Short_Cased_Fan.jpg",
    id: 1371,
  },
  {
    name: "FILTERS, AIR, FUEL, OIL, HYDRAULIC, TRANSMISSION",
    category: "TVH-Total Source Parts",
    brand: "",
    description: "",
    image: "/assets/tvh/FILTERS,_AIR,_FUEL,_OIL,_HYDRAULIC,_TRANSMISSION_1.jpg",
    id: 1372,
  },

  {
    name: "Cabin Chassis",
    category: "TVH-Total Source Parts",
    brand: "",
    description: "",
    image: "/assets/tvh/Cabin_Chassis_1.jpg",
    id: 1373,
  },

  {
    name: "Engine parts",
    category: "TVH-Total Source Parts",
    brand: "",
    description: "",
    image: "/assets/tvh/Engine_parts_1.jpg",
    id: 1374,
  },

  {
    name: "Hydraulics",
    category: "TVH-Total Source Parts",
    brand: "",
    description: "",
    image: "/assets/tvh/Hydraulics_1.jpg",
    id: 1375,
  },

  {
    name: "Mast Rollers Profiles Accessories",
    category: "TVH-Total Source Parts",
    brand: "",
    description: "",
    image: "/assets/tvh/Mast_Rollers_Profiles_Accessories_1.jpg",
    id: 1376,
  },

  {
    name: "Fuses Fuse Holders",
    category: "TVH-Total Source Parts",
    brand: "",
    description: "",
    image: "/assets/tvh/Fuses_Fuse_Holders_1.jpg",
    id: 1377,
  },

  {
    name: "Quality Seats for FORKLIFTS & PORT TRUCKS",
    category: "TVH-Total Source Parts",
    brand: "",
    description: "",
    image: "/assets/tvh/Quality_Seats_for_FORKLIFTS_&_PORT_TRUCKS_1.jpg",
    id: 1378,
  },

  {
    name: "Tele handlers",
    category: "TVH-Total Source Parts",
    brand: "",
    description: "",
    image: "/assets/tvh/Tele_handlers_1.jpg",
    id: 1379,
  },

  {
    name: "Scrubbers & Sweeper spares",
    category: "TVH-Total Source Parts",
    brand: "",
    description: "",
    image: "/assets/tvh/Scrubbers_&_Sweeper_spares_1.jpg",
    id: 1380,
  },

  {
    name: "Electronics accessories",
    category: "TVH-Total Source Parts",
    brand: "",
    description: "",
    image: "/assets/tvh/Electronics_accessories_1.jpg",
    id: 1381,
  },

  {
    name: "Auto Bulbs ",
    category: "TVH-Total Source Parts",
    brand: "",
    description: "",
    image: "/assets/tvh/Auto_Bulbs_1.jpg",
    id: 1382,
  },

  {
    name: "Batteries, Chargers and accessories",
    category: "TVH-Total Source Parts",
    brand: "",
    description: "",
    image: "/assets/tvh/Batteries,_Chargers_and_accessories_1.jpg",
    id: 1383,
  },

  {
    name: "Auto Paint & accessories",
    category: "TVH-Total Source Parts",
    brand: "",
    description: "",
    image: "/assets/tvh/Auto_Paint_&_accessories_1.jpg",
    id: 1384,
  },

  {
    name: "Pallet truck parts",
    category: "TVH-Total Source Parts",
    brand: "",
    description: "",
    image: "/assets/tvh/Pallet_truck_parts_1.jpg",
    id: 1385,
  },

  {
    name: "Potentiometers & encoders",
    category: "TVH-Total Source Parts",
    brand: "",
    description: "",
    image: "/assets/tvh/Potentiometers_&_encoders_1.jpg",
    id: 1386,
  },

  {
    name: "Pallet truck parts",
    category: "TVH-Total Source Parts",
    brand: "",
    description: "",
    image: "/assets/tvh/Pallet_truck_parts_1.jpg",
    id: 1387,
  },

  {
    name: "AUTO Air conditioning and parts",
    category: "TVH-Total Source Parts",
    brand: "",
    description: "",
    image: "/assets/tvh/AUTO_Air_conditioning_and_parts_1.jpg",
    id: 1388,
  },

  {
    name: "AUTO Document & accessories holders",
    category: "TVH-Total Source Parts",
    brand: "",
    description: "",
    image: "/assets/tvh/AUTO_Document_&_accessories_holders_1.jpg",
    id: 1389,
  },

  {
    name: "AUTO Drum handling &accessories",
    category: "TVH-Total Source Parts",
    brand: "",
    description: "",
    image: "/assets/tvh/AUTO_Drum_handling_&accessories_1.jpg",
    id: 1390,
  },

  {
    name: "AUTO Electronic accessories",
    category: "TVH-Total Source Parts",
    brand: "",
    description: "",
    image: "/assets/tvh/AUTO_Electronic_accessories_1.jpg",
    id: 1391,
  },

  {
    name: "AUTO Force professional tools",
    category: "TVH-Total Source Parts",
    brand: "",
    description: "",
    image: "/assets/tvh/AUTO_Force_professional_tools_1.jpg",
    id: 1392,
  },

  {
    name: "Forks & accessories",
    category: "TVH-Total Source Parts",
    brand: "",
    description: "",
    image: "/assets/tvh/Forks_&_accessories_1.jpg",
    id: 1393,
  },

  {
    name: "AUTO Hydraulic power units",
    category: "TVH-Total Source Parts",
    brand: "",
    description: "",
    image: "/assets/tvh/AUTO_Hydraulic_power_units_1.jpg",
    id: 1394,
  },

  {
    name: "AUTO Jacks & supports",
    category: "TVH-Total Source Parts",
    brand: "",
    description: "",
    image: "/assets/tvh/AUTO_Jacks_&_supports_1.jpg",
    id: 1395,
  },

  {
    name: "AUTO LED lights",
    category: "TVH-Total Source Parts",
    brand: "",
    description: "",
    image: "/assets/tvh/AUTO_LED_lights_1.jpg",
    id: 1396,
  },
  {
    name: "Printer",
    category: "Computer & IT Products",
    brand: "",
    description: "",
    image: "/assets/computer/Printer_1.jpg",
    id: 1397,
  },

  {
    name: "Mouse",
    category: "Computer & IT Products",
    brand: "",
    description: "",
    image: "/assets/computer/Mouse_1.jpg",
    id: 1398,
  },

  {
    name: "Scanner",
    category: "Computer & IT Products",
    brand: "",
    description: "",
    image: "/assets/computer/Scanner_1.jpg",
    id: 1399,
  },

  {
    name: "Desktop",
    category: "Computer & IT Products",
    brand: "",
    description: "",
    image: "/assets/computer/Desktop_1.jpg",
    id: 1400,
  },

  {
    name: "HDMI Cable",
    category: "Computer & IT Products",
    brand: "",
    description: "",
    image: "/assets/computer/HDMI_Cable_1.jpg",
    id: 1401,
  },

  {
    name: "Speaker",
    category: "Computer & IT Products",
    brand: "",
    description: "",
    image: "/assets/computer/Speaker_1.jpg",
    id: 1402,
  },

  {
    name: "Projector",
    category: "Computer & IT Products",
    brand: "",
    description: "",
    image: "/assets/computer/Projector_1.jpg",
    id: 1403,
  },

  {
    name: "WebCam",
    category: "Computer & IT Products",
    brand: "",
    description: "",
    image: "/assets/computer/WebCam_1.jpg",
    id: 1404,
  },

  {
    name: "CPU Cooler",
    category: "Computer & IT Products",
    brand: "",
    description: "",
    image: "/assets/computer/CPU_Cooler_1.jpg",
    id: 1405,
  },

  {
    name: "Trackball",
    category: "Computer & IT Products",
    brand: "",
    description: "",
    image: "/assets/computer/Trackball_1.jpg",
    id: 1406,
  },

  {
    name: "Display Port",
    category: "Computer & IT Products",
    brand: "",
    description: "",
    image: "/assets/computer/Display_Port_1.jpg",
    id: 1407,
  },

  {
    name: "Microphone",
    category: "Computer & IT Products",
    brand: "",
    description: "",
    image: "/assets/computer/Microphone_1.jpg",
    id: 1408,
  },

  {
    name: "CPU - Processors",
    category: "Computer & IT Products",
    brand: "",
    description: "",
    image: "/assets/computer/CPU_-_Processors_1.jpg",
    id: 1410,
  },

  {
    name: "Ink & Toner",
    category: "Computer & IT Products",
    brand: "",
    description: "",
    image: "/assets/computer/Ink_&_Toner_1.jpg",
    id: 1411,
  },
];

export { products, productscategories };
