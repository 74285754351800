import React from "react";
import {
  AppBar,
  Typography,
  Button,
  Toolbar,
  Box,
  Divider,
  MenuItem,
  Drawer,
} from "@mui/material";
import companyLogo from "../assets/companyLogo.png";
import companyLogo1 from "../assets/whiteTextLogoBl.png";

import MenuIcon from "@mui/icons-material/Menu";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { Menu as DropdownMenu } from "@mui/material";
import SearchBar from "./SearchBar";
import { Link, useNavigate } from "react-router-dom";
import { productscategories } from "../data/products";
import { toast } from "react-toastify";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import InstagramIcon from "@mui/icons-material/Instagram";
import MailIcon from "@mui/icons-material/Mail";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import { useMediaQuery } from "@mui/material";

const logoStyle = {
  width: "300px",
  cursor: "pointer",
};

export default function AppBarSticky() {
  const isSmallScreen = useMediaQuery("(max-width:900px)");

  const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const downloadPDF = () => {
    const link = document.createElement("a");
    link.href = "/assets/Smart_GT_EBrouchure.pdf"; // Replace with the actual path to your PDF file
    link.download = "Smart GT E-Brouchure.pdf"; // Name of the file when downloaded
    link.click();
    toast.success("E-Brouchure Downloaded...", {
      theme: "dark",
    });
  };
  const navigate = useNavigate();

  const handleMenuItemClick = (sectionId) => {
    // Navigate to the root route
    navigate("/");
    // Scroll to the 'highlights' section after navigation
    setTimeout(() => {
      scrollToSection(sectionId);
    }, 100); // Small delay to ensure navigation is complete
  };
  const scrollToSection = (sectionId) => {
    const sectionElement = document.getElementById(sectionId);
    const offset = 128;
    if (sectionElement) {
      const targetScroll = sectionElement.offsetTop - offset;
      sectionElement.scrollIntoView({ behavior: "smooth" });
      window.scrollTo({
        top: targetScroll,
        behavior: "smooth",
      });
      setOpen(false);
    }
  };

  return (
    <>
      <Box
        sx={{
          background: {
            xs: "black",
            md: "linear-gradient(315deg, #fff 74%, #000 0%);",
          },
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          alignItems: "center",
          px: 6,
          py: 3,
          width: "100%",
        }}
      >
        <a href="/">
          <img src={companyLogo1} style={logoStyle} alt="Company Logo" />
        </a>
        <Box
          sx={{
            flexGrow: 1,
            display: "flex",
            justifyContent: "center", // Center SearchBar horizontally
            my: { xs: 1, md: 0 },
            width: "100%", // Ensure the inner Box takes full width of its container
          }}
        >
          <SearchBar />
        </Box>
        {!isSmallScreen && (
          <div style={{ width: "300px" }}>
            <Box
              sx={{
                display: "flex", // Using "flex" for both small and medium sizes.
                flexDirection: "column",
                alignItems: "end",
              }}
            >
              <p style={{ color: "black" }}>Abu Dhabi, UAE</p>
              <p style={{ color: "black" }}>+971 552 553 056</p>
              <div display="flex" direction="row" justifyItems="space-between">
                <IconButton
                  target="_blank"
                  onClick={() => {
                    window.open(
                      "https://wa.me/message/E6ISHYZGBBKAE1",
                      "_blank"
                    );
                  }}
                  aria-label="WhatsApp"
                >
                  <WhatsAppIcon sx={{ color: "black", fontSize: "20px" }} />
                </IconButton>
                <IconButton
                  onClick={() => {
                    window.open(
                      "https://instagram.com/smartglobalgt",
                      "_blank"
                    );
                  }}
                  aria-label="Insta"
                >
                  <InstagramIcon sx={{ color: "black", fontSize: "20px" }} />
                </IconButton>
                <IconButton
                  target="_blank"
                  href="mailto:smartsales@smartglobalgt.com"
                  aria-label="Gmail"
                >
                  <MailIcon sx={{ color: "black", fontSize: "20px" }} />
                </IconButton>
              </div>
            </Box>
          </div>
        )}
      </Box>

      <AppBar
        position="sticky"
        sx={{
          boxShadow: 0,
          bgcolor: "transparent",
          backgroundImage: "none",
        }}
      >
        <Toolbar
          variant="regular"
          sx={(theme) => ({
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            flexShrink: 0,
            backdropFilter: "blur(10px) brightness(90%)",
            maxHeight: 40,
            backgroundColor: "#000",
            boxShadow: `0 4px 8px rgba(0, 0, 0, 0.1), 0 2px 4px rgba(0, 0, 0, 0.15)`,
          })}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              ml: "-18px",
              px: 0,
            }}
          >
            <Button
              onClick={handleMenuOpen}
              endIcon={<ArrowDropDownIcon />}
              sx={{ textTransform: "none", color: "white", minWidth: "200px" }}
            >
              Categories
            </Button>
            <DropdownMenu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
            >
              {productscategories.map((pro, index) => (
                <MenuItem
                  component={Link}
                  to={`/products/${pro.name
                    .replace(/ /g, "_")
                    .toString()
                    .toLowerCase()}`}
                  onClick={handleMenuClose}
                  key={index}
                  sx={{
                    position: "relative",
                    "&:hover::after": {
                      content: '""',
                      position: "absolute",
                      left: 0,
                      bottom: -2, // 2 pixels below the text
                      width: "100%",
                      height: "2px",
                      backgroundColor: "black",
                      transition: "transform 0.3s ease",
                      transform: "scaleX(1)",
                    },
                    "&::after": {
                      content: '""',
                      position: "absolute",
                      left: 0,
                      bottom: -2,
                      width: "0%",
                      height: "2px",
                      backgroundColor: "white",
                      transition: "width 0.3s ease",
                      transform: "scaleX(0)",
                    },
                    py: "6px",
                    px: "12px",
                  }}
                >
                  {pro.name}
                </MenuItem>
              ))}
            </DropdownMenu>
          </Box>

          <Box
            sx={{
              display: { xs: "none", md: "flex" },
              justifyItems: "center",
            }}
          >
            <MenuItem
              component={Link}
              to="/"
              sx={{
                position: "relative",
                "&:hover::after": {
                  content: '""',
                  position: "absolute",
                  left: 0,
                  bottom: -2,
                  width: "100%",
                  height: "2px",
                  backgroundColor: "white",
                  transition: "transform 0.3s ease",
                  transform: "scaleX(1)",
                },
                "&::after": {
                  content: '""',
                  position: "absolute",
                  left: 0,
                  bottom: -2,
                  width: "0%",
                  height: "2px",
                  backgroundColor: "white",
                  transition: "width 0.3s ease",
                  transform: "scaleX(0)",
                },
                py: "6px",
                px: "12px",
              }}
            >
              <Typography variant="body2" color="#fff" fontSize="18px">
                Home
              </Typography>
            </MenuItem>
            <MenuItem
              component={Link}
              to="/products"
              sx={{
                position: "relative",
                "&:hover::after": {
                  content: '""',
                  position: "absolute",
                  left: 0,
                  bottom: -2,
                  width: "100%",
                  height: "2px",
                  backgroundColor: "white",
                  transition: "transform 0.3s ease",
                  transform: "scaleX(1)",
                },
                "&::after": {
                  content: '""',
                  position: "absolute",
                  left: 0,
                  bottom: -2,
                  width: "0%",
                  height: "2px",
                  backgroundColor: "white",
                  transition: "width 0.3s ease",
                  transform: "scaleX(0)",
                },
                py: "6px",
                px: "12px",
              }}
            >
              <Typography variant="body2" color="#fff" fontSize="18px">
                Products
              </Typography>
            </MenuItem>
            <MenuItem
              component={Link}
              to="/brands"
              sx={{
                position: "relative",
                "&:hover::after": {
                  content: '""',
                  position: "absolute",
                  left: 0,
                  bottom: -2,
                  width: "100%",
                  height: "2px",
                  backgroundColor: "white",
                  transition: "transform 0.3s ease",
                  transform: "scaleX(1)",
                },
                "&::after": {
                  content: '""',
                  position: "absolute",
                  left: 0,
                  bottom: -2,
                  width: "0%",
                  height: "2px",
                  backgroundColor: "white",
                  transition: "width 0.3s ease",
                  transform: "scaleX(0)",
                },
                py: "6px",
                px: "12px",
              }}
            >
              <Typography variant="body2" color="#fff" fontSize="18px">
                Brands
              </Typography>
            </MenuItem>
            <MenuItem
              component={Link}
              to="/e-brouchure"
              sx={{
                position: "relative",
                "&:hover::after": {
                  content: '""',
                  position: "absolute",
                  left: 0,
                  bottom: -2,
                  width: "100%",
                  height: "2px",
                  backgroundColor: "white",
                  transition: "transform 0.3s ease",
                  transform: "scaleX(1)",
                },
                "&::after": {
                  content: '""',
                  position: "absolute",
                  left: 0,
                  bottom: -2,
                  width: "0%",
                  height: "2px",
                  backgroundColor: "white",
                  transition: "width 0.3s ease",
                  transform: "scaleX(0)",
                },
                py: "6px",
                px: "12px",
              }}
            >
              <Typography variant="body2" color="#fff" fontSize="18px">
                E-Brouchure
              </Typography>
            </MenuItem>
            <MenuItem
              onClick={() => handleMenuItemClick("highlights")}
              sx={{
                position: "relative",
                "&:hover::after": {
                  content: '""',
                  position: "absolute",
                  left: 0,
                  bottom: -2,
                  width: "100%",
                  height: "2px",
                  backgroundColor: "white",
                  transition: "transform 0.3s ease",
                  transform: "scaleX(1)",
                },
                "&::after": {
                  content: '""',
                  position: "absolute",
                  left: 0,
                  bottom: -2,
                  width: "0%",
                  height: "2px",
                  backgroundColor: "white",
                  transition: "width 0.3s ease",
                  transform: "scaleX(0)",
                },
                py: "6px",
                px: "12px",
              }}
            >
              <Typography variant="body2" color="#fff" fontSize="18px">
                Why Us
              </Typography>
            </MenuItem>
            <MenuItem
              onClick={() => handleMenuItemClick("faq")}
              sx={{
                position: "relative",
                "&:hover::after": {
                  content: '""',
                  position: "absolute",
                  left: 0,
                  bottom: -2,
                  width: "100%",
                  height: "2px",
                  backgroundColor: "white",
                  transition: "transform 0.3s ease",
                  transform: "scaleX(1)",
                },
                "&::after": {
                  content: '""',
                  position: "absolute",
                  left: 0,
                  bottom: -2,
                  width: "0%",
                  height: "2px",
                  backgroundColor: "white",
                  transition: "width 0.3s ease",
                  transform: "scaleX(0)",
                },
                py: "6px",
                px: "12px",
              }}
            >
              <Typography variant="body2" color="#fff" fontSize="18px">
                FAQ
              </Typography>
            </MenuItem>
          </Box>
          <Box
            sx={{
              display: { xs: "none", md: "flex" },
              gap: 0.5,
              alignItems: "center",
            }}
          >
            {/* <ToggleColorMode mode={mode} toggleColorMode={toggleColorMode} /> */}
            {/* <Button
              color="primary"
              variant="text"
              size="small"
              component="a"
              href="/"
              target="_blank"
            >
              Shop Now
            </Button> */}
            <Button
              size="small"
              component="a"
              onClick={() => scrollToSection("getintouch")}
              sx={{
                backgroundColor: "#b91c1c", // Black background
                color: "#fff", // White text
                borderRadius: 2, // Squared corners
                "&:hover": {
                  backgroundColor: "#000", // White background on hover
                  color: "#fff", // Black text on hover
                },
                padding: "6px 12px", // Adjust padding if needed
                textTransform: "none", // Prevent uppercase transformation
              }}
            >
              Contact Us
            </Button>
          </Box>
          <Box sx={{ display: { sm: "", md: "none" } }}>
            <Button
              variant="text"
              aria-label="menu"
              onClick={toggleDrawer(true)}
              sx={{ minWidth: "30px", p: "4px", color: "#C71918" }}
            >
              <MenuIcon />
            </Button>
            <Drawer anchor="right" open={open} onClose={toggleDrawer(false)}>
              <Box
                sx={{
                  minWidth: "60dvw",
                  p: 2,
                  backgroundColor: "background.paper",
                  flexGrow: 1,
                }}
              >
                {/* <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "end",
                    flexGrow: 1,
                  }}
                >
                  <ToggleColorMode
                    mode={mode}
                    toggleColorMode={toggleColorMode}
                  />
                </Box> */}
                <Box
                  sx={{
                    mt: "auto", // Pushes the logo to the bottom of the drawer
                    textAlign: "center",
                    p: 1,
                  }}
                >
                  <img
                    src={companyLogo}
                    alt="Company Logo"
                    style={{ width: "140px" }}
                  />
                </Box>
                <MenuItem onClick={() => scrollToSection("hero")}>
                  Home
                </MenuItem>
                <MenuItem component={Link} to="/products">
                  Products
                </MenuItem>
                <MenuItem component={Link} to="/brands">
                  Brands
                </MenuItem>
                <MenuItem component={Link} to="/e-brouchure">
                  E-brouchure
                </MenuItem>
                <MenuItem onClick={() => handleMenuItemClick("highlights")}>
                  Why Us
                </MenuItem>
                {/* <MenuItem onClick={() => scrollToSection("pricing")}>
                  Testimonials
                </MenuItem> */}
                <MenuItem onClick={() => handleMenuItemClick("faq")}>
                  FAQ
                </MenuItem>
                <Divider />
                <MenuItem>
                  <Button
                    color="primary"
                    variant="contained"
                    component="a"
                    href="tel:+971552553056" // Replace with your phone number
                    sx={{ width: "100%" }}
                  >
                    Call Us
                  </Button>
                </MenuItem>

                <MenuItem>
                  <Button
                    variant="contained"
                    type="submit"
                    onClick={() => {
                      window.open(
                        "https://wa.me/message/E6ISHYZGBBKAE1",
                        "_blank"
                      );
                    }}
                    sx={{ background: "#075e54", width: "100%" }}
                  >
                    <WhatsAppIcon
                      sx={{
                        color: "white",
                        marginRight: "2px",
                      }}
                    />
                    WhatsApp Us!
                  </Button>
                </MenuItem>
                {/* <MenuItem>
                  <Button
                    color="primary"
                    variant="outlined"
                    component="a"
                    href="/"
                    target="_blank"
                    sx={{ width: "100%" }}
                  >
                    Shop Now
                  </Button>
                </MenuItem> */}
              </Box>
            </Drawer>
          </Box>
        </Toolbar>
      </AppBar>
    </>
  );
}
