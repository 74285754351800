// src/pages/BrandingPage.js
import React from "react";
import { Box, Typography, Card, CardContent } from "@mui/material";
import Container from "@mui/material/Container";
import Masonry from "@mui/lab/Masonry";
import { useMediaQuery } from "@mui/material";
import { products, productscategories } from "../data/products"; // Adjust the path as necessary
import { useParams, Link } from "react-router-dom";

function ProductsPage() {
  const isSmallScreen = useMediaQuery("(max-width:600px)");
  const columns = isSmallScreen ? 2 : 5;
  const { category } = useParams();
  const proCategory = productscategories.find(
    (cat) => cat.name.replace(/ /g, "_").toString().toLowerCase() === category
  );

  return (
    <Container
      id="testimonials"
      sx={{
        pt: { xs: 4, sm: 4 },
        pb: { xs: 4, sm: 6 },
        position: "relative",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: { xs: 3, sm: 6 },
      }}
    >
      <Box
        sx={{
          width: { sm: "100%", md: "100%" },
          textAlign: { sm: "left", md: "center" },
        }}
      >
        <Typography component="h2" variant="h4" color="text.primary">
          We specialize in {proCategory.name}
        </Typography>
        <Typography variant="body1" color="text.secondary">
          {proCategory.description}
        </Typography>
      </Box>
      <Masonry columns={columns} spacing={2}>
        {products
          .filter((product) =>
            product.category
              .toLowerCase()
              .includes(category.replaceAll("_", " ").toLowerCase())
          )
          .map((product, index) => (
            <Card
              component={Link}
              to={`/checkout/${product.id}`}
              sx={{ textDecoration: "none" }} // Ensure no underline
            >
              <CardContent
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center", // Center content horizontally
                  justifyContent: "center", // Center content vertically if needed
                  textAlign: "center", // Center text
                  padding: 2, // Adjust padding if necessary
                }}
              >
                <img
                  src={product.image}
                  alt={product.name}
                  style={{
                    height: "80px",
                    width: "auto", // Maintain aspect ratio
                    objectFit: "contain", // Ensure the image is contained within the dimensions
                    marginBottom: "8px",
                  }}
                />
                <Typography variant="body2" color="text.primary">
                  {product.name}
                </Typography>
              </CardContent>
            </Card>
          ))}
      </Masonry>
    </Container>
  );
}

export default ProductsPage;
