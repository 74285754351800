// src/pages/BrandingPage.js
import React from "react";
import { Box, Typography, Card, CardContent } from "@mui/material";
import Container from "@mui/material/Container";
import Masonry from "@mui/lab/Masonry";
import { useMediaQuery } from "@mui/material";
import brands from "../data/brand"; // Adjust the path as necessary
function BrandsPage() {
  const isSmallScreen = useMediaQuery("(max-width:600px)");
  const columns = isSmallScreen ? 2 : 5;

  return (
    <Container
      id="testimonials"
      sx={{
        pt: { xs: 4, sm: 4 },
        pb: { xs: 4, sm: 6 },
        position: "relative",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: { xs: 3, sm: 6 },
      }}
    >
      <Box
        sx={{
          width: { sm: "100%", md: "60%" },
          textAlign: { sm: "left", md: "center" },
        }}
      >
        <Typography component="h2" variant="h4" color="text.primary">
          Brands we deal with!
        </Typography>
        {/* <Typography variant="body1" color="text.secondary">
          See what our customers love about our products.
        </Typography> */}
      </Box>
      <Masonry columns={columns} spacing={2}>
        {brands.map((brand, index) => (
          <Card>
            <CardContent
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              <img
                src={brand.image}
                alt={brand.name}
                style={{
                  height: "80px",
                  width: "auto",
                  objectFit: "contain",
                  marginBottom: "8px",
                }}
              />
              <Typography variant="body2" color="text.primary">
                {brand.name}
              </Typography>
            </CardContent>
          </Card>
        ))}
      </Masonry>
    </Container>
  );
}

export default BrandsPage;
